import apiCaller from './api-caller'

const RESOURCES = {
  user: '/user'
}

export default {
  getBoUsers(headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.user}/list`, headers })
  },

  forceUpdateBoUser(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.user}/${id}`, data, headers })
  },

  deleteBoUser(id, headers) {
    return apiCaller({ method: 'DELETE', url: `${RESOURCES.user}/${id}`, headers })
  },

  register(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.user}/register`, data, headers })
  }
}
