import dataSets from '../../support/data-sets'

import {
  STORE_STAKING_USERS,
  STORE_STAKING_USER,
  CLEAR_STAKING_USER,
  STORE_STAKING_PLANS,
  STORE_STAKING_PLAN,
  STORE_PROGRAM_ID,
  STORE_STAKING_INFO,
  UPDATE_STAKING_INFO,
  STORE_STAKING_SETTINGS,
  UPDATE_STAKING_SETTING
} from '../mutation-types'

const mutations = {
  [STORE_STAKING_INFO](state, payload) {
    state.stakingInfo = payload
  },

  [UPDATE_STAKING_INFO](state, payload) {
    state.stakingInfo = payload
  },

  [STORE_PROGRAM_ID](state, payload) {
    state.programId = payload
  },

  [STORE_STAKING_USERS](state, payload) {
    state.stakingUsers = payload
  },

  [STORE_STAKING_USER](state, payload) {
    state.stakingUser = payload
  },

  [CLEAR_STAKING_USER](state) {
    state.stakingUser = null
  },

  [STORE_STAKING_PLANS](state, payload) {
    state.stakingPlans.total = payload.total
    state.stakingPlans.plans = payload.data
  },

  [STORE_STAKING_PLAN](state, payload) {
    state.stakingPlan = payload
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initStakingUsersAssets
  },

  [STORE_STAKING_SETTINGS](state, payload) {
    state.stakingSettings = payload
  },

  [UPDATE_STAKING_SETTING](state, payload) {
    const index = state.stakingSettings.findIndex(info => info.id === payload.id)
    if (index < 0) return

    state.stakingSettings[index] = payload
  }
}

export default mutations
