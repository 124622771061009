import apiCaller from './api-caller'

const RESOURCES = {
  transaction: '/tnx'
}

export default {
  getTransactions(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.transaction}/list`, params, headers })
  },

  addTransaction(data, headers) {
    return apiCaller({ method: 'POST', url: RESOURCES.transaction, data, headers })
  }
}
