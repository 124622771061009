import lStorage from '@/local-storage'

export const authorizationHeader = () => {
  const accessToken = lStorage.get('accessToken')
  const requestHeader = {
    Authorization: `Bearer ${accessToken.token}`
  }

  return requestHeader
}
