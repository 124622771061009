<template>
  <div :class="['modal-wrapper', { active: open }]">
    <span class="modal-overlay" @click="toggle"></span>
    <div class="modal">
      <div class="modal__content text-center">
        {{ confirmText }}

        <slot />
      </div>

      <div class="modal__footer">
        <button class="btn btn--full modal__action" :class="cancelButtonClass" @click="toggle">
          {{ cancelButtonText }}
        </button>

        <button
          v-if="isConfirm"
          class="btn btn--full modal__action"
          :class="confirmButtonClass"
          @click="$emit('confirm')"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import modal from '@/mixins/modal'

export default {
  name: 'ConfirmDialog',

  mixins: [modal],

  props: {
    confirmText: {
      type: String,
      require: true
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },

    cancelButtonClass: {
      type: String,
      require: false
    },

    confirmButtonText: {
      type: String,
      default: 'Delete'
    },

    confirmButtonClass: {
      type: String,
      default: 'text-error'
    },

    isConfirm: {
      type: Boolean,
      default: true
    }
  }
}
</script>
