import Web3 from 'web3'
import bigInt from 'big-integer'
import { TNX_STATUS } from './key-mapping'

// generate offchain byte from offchain string
export const generateOffchainByte = str => {
  let offchain = Web3.utils.toHex(str)
  for (let i = offchain.length; i < 66; i++) {
    offchain += '0'
  }

  return offchain
}

// get amount with pow decimal
export const getAmountWithDecimal = (amountShort, decimal) => {
  return amountShort * bigInt(10).pow(parseInt(decimal))
}

// get cf request transfer tnx request body data
export const getCfRequestTransferTnxRequest = (hash, account, data, contract, requestType) => {
  const { offchain, amount, token, id, r_id } = data
  return {
    tnxHash: hash,
    assetID: 825,
    from: account,
    to: contract.address,
    amountShort: amount,
    assetSymbol: token.symbol,
    status: TNX_STATUS.pending,
    contractAddress: contract.address,
    contractID: contract.id,
    optionsData: {
      crowdfundingRequestTransfer: {
        type: requestType,
        requestId: id,
        rId: parseInt(r_id),
        tokenId: token.id,
        tokenAddress: token.address,
        offchain: offchain
      }
    }
  }
}
