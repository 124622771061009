import apiCaller from './api-caller'

const RESOURCES = {
  appVersions: '/force-update',
  referral: '/referral/setting',
  adminAmount: '/admin/amount',
  currencyConverter: '/convert-currency'
}

export default {
  getAppVersions(headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.appVersions}/list`, headers })
  },

  updateAppVersion(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.appVersions}/${id}`, data, headers })
  },

  getReferralSetting(headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.referral}/list`, headers })
  },

  updateReferralSetting(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.referral}/${id}`, data, headers })
  },

  getAdminAmounts(headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.adminAmount, headers })
  },

  getConvertCurrency(headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.currencyConverter}/read`, headers })
  }
}
