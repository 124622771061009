const state = {
  platform: {
    ios: null,
    android: null
  },
  referralReward: null,
  kycReward: null,
  adminReferral: null,
  convertCurrency: null
}

export default state
