import { STORE_BO_USERS, UPDATE_BO_USER, DELETE_BO_USER } from '../mutation-types'

const mutations = {
  [STORE_BO_USERS](state, payload) {
    state.boUsers = payload
  },

  [UPDATE_BO_USER](state, payload) {
    const index = state.boUsers.findIndex(user => user.id === payload.id)
    if (index < 0) return

    state.boUsers.splice(index, 1, payload)
  },

  [DELETE_BO_USER](state, payload) {
    const index = state.boUsers.findIndex(user => user.id === payload)
    if (index < 0) return

    state.boUsers.splice(index, 1)
  }
}

export default mutations
