<template>
  <aside class="sidebar">
    <img src="@/assets/img_logo.png" alt="logo wallet" width="168" />

    <nav>
      <ul class="sidebar__items">
        <group-navigation class="sidebar__item" name="Dashboard" :navigations="dashboardNavigations">
          <template v-slot:header>
            <div class="tile">
              <div class="tile__image">
                <toko-dashboard-icon />
              </div>
              <p class="tile__content">Dashboard</p>
            </div>
          </template>
        </group-navigation>

        <group-navigation class="sidebar__item" name="Programs" :navigations="programNavigations">
          <template v-slot:header>
            <div class="tile">
              <div class="tile__image">
                <toko-plan-icon />
              </div>
              <p class="tile__content">Programs</p>
            </div>
          </template>
        </group-navigation>

        <group-navigation class="sidebar__item" name="Users" :navigations="userNavigations">
          <template v-slot:header>
            <div class="tile">
              <div class="tile__image">
                <toko-users-icon />
              </div>
              <p class="tile__content">Users</p>
            </div>
          </template>
        </group-navigation>

        <router-link
          v-if="currentUser && $auth('Page', currentUser, $accessRule.UserView).canView()"
          to="/admin"
          tag="li"
          class="tile tile--box sidebar__item"
        >
          <div class="tile__image">
            <toko-admin-icon />
          </div>
          <p class="tile__content">User Management</p>
        </router-link>

        <group-navigation class="sidebar__item" name="Transactions" :navigations="transactionNavigations">
          <template v-slot:header>
            <div class="tile">
              <div class="tile__image">
                <toko-transaction-icon />
              </div>
              <p class="tile__content">Transactions</p>
            </div>
          </template>
        </group-navigation>

        <group-navigation
          v-if="currentUser && $auth('Page', currentUser, $accessRule.SwapTokoView).canView()"
          class="sidebar__item"
          name="SwapToko"
          :navigations="swapTokoGroupNavigations"
        >
          <template v-slot:header>
            <div class="tile">
              <div class="tile__image">
                <toko-swap-icon />
              </div>
              <p class="tile__content">TOKO Ver.1 Co.</p>
            </div>
          </template>
        </group-navigation>

        <router-link to="/withdraw" tag="li" class="tile tile--box sidebar__item">
          <div class="tile__image">
            <toko-withdraw-icon />
          </div>
          <p class="tile__content">Withdraw Requests</p>
        </router-link>

        <router-link to="/balance" tag="li" class="tile tile--box sidebar__item">
          <div class="tile__image">
            <toko-wallet-icon />
          </div>
          <p class="tile__content">Balance</p>
        </router-link>

        <router-link to="/monitoring" tag="li" class="tile tile--box sidebar__item">
          <div class="tile__image">
            <toko-monitoring-icon />
          </div>
          <p class="tile__content">Monitor</p>
        </router-link>

        <router-link to="/audit-logs" tag="li" class="tile tile--box sidebar__item">
          <div class="tile__image">
            <toko-monitoring-icon />
          </div>
          <p class="tile__content">Audit logs</p>
        </router-link>

        <router-link to="/settings" tag="li" class="tile tile--box sidebar__item">
          <div class="tile__image">
            <toko-setting-icon />
          </div>
          <p class="tile__content">Settings</p>
        </router-link>

        <router-link to="/profile" tag="li" class="tile tile--box sidebar__item">
          <div class="tile__image">
            <toko-profile-sm-icon />
          </div>
          <p class="tile__content">Profile</p>
        </router-link>

        <li class="tile tile--box sidebar__item" ref="logoutButton" @click="$emit('on-logout')">
          <div class="tile__image">
            <toko-logout-icon />
          </div>
          <p class="tile__content">Logout</p>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import {
  DASHBOARD_GROUP_NAVIGATION,
  USER_GROUP_NAVIGATION,
  PROGRAM_GROUP_NAVIGATION,
  TRANSACTION_GROUP_NAVIGATION,
  SWAP_TOKO_GROUP_NAVIGATION
} from '@/utils/data-sets'
import GroupNavigation from '@/components/GroupNavigation'
import TokoDashboardIcon from '@/ui/toko-icons/TokoDashboard'
import TokoPlanIcon from '@/ui/toko-icons/TokoPlan'
import TokoUsersIcon from '@/ui/toko-icons/TokoUsers'
import TokoAdminIcon from '@/ui/toko-icons/TokoAdmin'
import TokoWithdrawIcon from '@/ui/toko-icons/TokoWithdraw'
import TokoTransactionIcon from '@/ui/toko-icons/TokoTransaction'
import TokoWalletIcon from '@/ui/toko-icons/TokoWallet'
import TokoProfileSmIcon from '@/ui/toko-icons/TokoProfileSm'
import TokoSettingIcon from '@/ui/toko-icons/TokoSetting'
import TokoMonitoringIcon from '@/ui/toko-icons/TokoMonitoring'
import TokoLogoutIcon from '@/ui/toko-icons/TokoLogout'
import TokoSwapIcon from '@/ui/toko-icons/TokoSwap'

export default {
  name: 'AppSidebar',

  data() {
    return {
      dashboardNavigations: DASHBOARD_GROUP_NAVIGATION,
      userNavigations: USER_GROUP_NAVIGATION,
      programNavigations: PROGRAM_GROUP_NAVIGATION,
      transactionNavigations: TRANSACTION_GROUP_NAVIGATION,
      swapTokoGroupNavigations: SWAP_TOKO_GROUP_NAVIGATION
    }
  },

  components: {
    GroupNavigation,
    TokoDashboardIcon,
    TokoPlanIcon,
    TokoUsersIcon,
    TokoAdminIcon,
    TokoWithdrawIcon,
    TokoTransactionIcon,
    TokoWalletIcon,
    TokoProfileSmIcon,
    TokoSettingIcon,
    TokoMonitoringIcon,
    TokoLogoutIcon,
    TokoSwapIcon
  }
}
</script>
