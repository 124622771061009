import {
  STORE_CURRENT_WALLET_ACCOUNT,
  TOGGLE_DAPP_WAITING,
  CLEAR_CURRENT_WALLET_ACCOUNT,
  STORE_CONNECTED_STATUS
} from '../mutation-types'

const mutations = {
  [STORE_CURRENT_WALLET_ACCOUNT](state, payload) {
    state.account = payload
  },

  [CLEAR_CURRENT_WALLET_ACCOUNT](state) {
    state.account = ''
  },

  [TOGGLE_DAPP_WAITING](state) {
    state.waiting = !state.waiting
  },

  [STORE_CONNECTED_STATUS](state, payload) {
    state.isConnected = payload
  }
}

export default mutations
