import apiCaller from './api-caller'

const RESOURCES = {
  userProfile: '/auth/user-profile',
  login: '/auth/login',
  changePassword: '/auth/change-pwd'
}

export default {
  getUserProfile(headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.userProfile, headers })
  },

  updateUserProfile(data, headers) {
    return apiCaller({ method: 'PUT', url: RESOURCES.userProfile, data, headers })
  },

  login(data) {
    return apiCaller({ method: 'POST', url: RESOURCES.login, data })
  },

  changePassword(data, headers) {
    return apiCaller({ method: 'POST', url: RESOURCES.changePassword, data, headers })
  }
}
