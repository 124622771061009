export default {
  UserView: 'userView',
  UserUpdate: 'userUpdate',
  CrowdfundingAdd: 'crowdfundingAdd',
  CrowdfundingView: 'crowdfundingView',
  KycApprove: 'kycApprove',
  KycView: 'kycView',
  PlatformSettingUpdate: 'platformSettingUpdate',
  PlatformSettingView: 'platformSettingView',
  ReferralSettingUpdate: 'referralSettingUpdate',
  ReferralSettingView: 'referralSettingView',
  CrowdfundingAdminView: 'crowdfundingAdminView',
  CrowdfundingAdminUpdate: 'crowdfundingAdminUpdate',
  ProgramView: 'programView',
  ProgramUpdate: 'programUpdate',
  SwapTokoView: 'swapTokoView',
  SwapTokoUpdate: 'swapTokoUpdate',
  SwapTokoTrigger: 'swapTokoTrigger',
  PaymentOrderView: 'paymentOrderView',
  PaymentOrderReview: 'paymentOrderReview',
  ConvertTokoView: 'convertTokoReview',
  ConvertTokoSettingUpdate: 'convertTokoSettingUpdate',
  StakingSettingUpdate: 'stakingSettingUpdate',
  ApproveJoinStaking: 'approveJoinStaking'
}
