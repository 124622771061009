import { authorizationHeader } from '@/store/support/authorization'
import ConvertTokoService from '@/services/convert-toko'
import TransactionService from '@/services/transaction'
import UserService from '@/services/user'

const actions = {
  getConvertTokoOrders({ commit }, params) {
    return ConvertTokoService.getConvertTokoOrders(params, authorizationHeader()).then(response => {
      commit('STORE_CONVERT_TOKO_ORDERS', response.data.result)

      return response
    })
  },

  getConvertTokoOrder({ commit, dispatch }, id) {
    return ConvertTokoService.getConvertTokoOrder(id, authorizationHeader()).then(response => {
      const result = response.data.result.data
      commit('STORE_CONVERT_TOKO_ORDER', result)

      if (result?.tnxHashFrom) {
        dispatch('getConvertTnxFrom', result.tnxHashFrom)
      }

      if (result?.tnxHashTo) {
        dispatch('getConvertTnxTo', result.tnxHashTo)
      }

      return response
    })
  },

  getConvertTnxFrom({ commit }, tnxHash) {
    return TransactionService.getTransactions({ tnxHash }, authorizationHeader()).then(async response => {
      const tnxes = response.data.result.data
      if (tnxes && tnxes.length > 0) {
        const userID = tnxes[0].userId
        let userData
        if (userID > 0) {
          userData = await UserService.getUser(userID, authorizationHeader())
        } else {
          if (tnxes[0].optionsData && tnxes[0].optionsData.crowdfundingFeed) {
            userData = await UserService.getUser(tnxes[0].optionsData.crowdfundingFeed.userId, authorizationHeader())
          } else {
            userData = { data: { result: { data: { email: 'admin' } } } }
          }
        }

        commit('STORE_CONVERT_TOKO_TNX_FROM', { ...tnxes[0], user: userData.data.result.data })
      }

      return response
    })
  },

  getConvertTnxTo({ commit }, tnxHash) {
    return TransactionService.getTransactions({ tnxHash }, authorizationHeader()).then(async response => {
      const tnxes = response.data.result.data
      if (tnxes && tnxes.length > 0) {
        const userID = tnxes[0].userId
        let userData
        if (userID > 0) {
          userData = await UserService.getUser(userID, authorizationHeader())
        } else {
          if (tnxes[0].optionsData && tnxes[0].optionsData.crowdfundingFeed) {
            userData = await UserService.getUser(tnxes[0].optionsData.crowdfundingFeed.userId, authorizationHeader())
          } else {
            userData = { data: { result: { data: { email: 'admin' } } } }
          }
        }

        commit('STORE_CONVERT_TOKO_TNX_TO', { ...tnxes[0], user: userData.data.result.data })
      }

      return response
    })
  },

  getConvertTokoSettings({ commit }) {
    return ConvertTokoService.getConvertTokoSettings(authorizationHeader()).then(response => {
      commit('STORE_CONVERT_TOKO_SETTINGS', response.data.result.data)

      return response
    })
  },

  updateConvertTokoSetting({ commit }, settingInfo) {
    return ConvertTokoService.updateConvertTokoSetting(settingInfo, authorizationHeader()).then(response => {
      commit('UPDATE_CONVERT_TOKO_SETTING', response.data.result.data)
      commit('alert/STORE_ALERT', { type: 'success', content: 'Update Successfully', timeOut: 2400 }, { root: true })

      return response
    })
  }
}

export default actions
