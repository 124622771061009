import { STORE_PROGRAM_LIST, STORE_REPORT_PROGRAM_BALANCES } from '../mutation-types'

const mutations = {
  [STORE_PROGRAM_LIST](state, payload) {
    state.programList = payload
  },

  [STORE_REPORT_PROGRAM_BALANCES](state, payload) {
    state.programBalances = payload
  }
}

export default mutations
