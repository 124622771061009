import ReportService from '@/services/report'
import { authorizationHeader } from '@/store/support/authorization'

const actions = {
  getReportBalanceSummary({ commit }, params) {
    return ReportService.getReportBalanceSummary(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_BALANCE_SUMMARY', response.data.result.data)
      return response
    })
  },

  getReportBalanceChart({ commit }, params) {
    return ReportService.getReportBalanceChart(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_BALANCE_CHART', response.data.result.data)
      return response
    })
  },

  getReportJoinedUsersChart({ commit }, params) {
    return ReportService.getReportJoinedUsersChart(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_JOINED_USERS_CHART', response.data.result.data)
      return response
    })
  },

  getReportRegisterChart({ commit }, params) {
    return ReportService.getReportRegisterChart(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_REGISTER_CHART', response.data.result.data)
      return response
    })
  },

  getReportPaymentOrder({ commit }, params) {
    return ReportService.getReportPaymentSummary(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_PAYMENT_ORDER_SUMMARY', response.data.result.data)

      return response
    })
  }
}

export default actions
