import SwapTokoService from '@/services/swap-toko'
import { authorizationHeader } from '@/store/support/authorization'
import * as message from '@/store/support/message'
import lStorage from '@/local-storage'

const actions = {
  getSwapTokoStakings({ commit }, params) {
    return SwapTokoService.getSwapTokoStakings(params, authorizationHeader()).then(response => {
      commit('STORE_SWAP_TOKO_STAKINGS', response.data.result)

      return response
    })
  },

  getSwapTokoStaking({ commit }, id) {
    return SwapTokoService.getSwapTokoStaking(id, authorizationHeader()).then(response => {
      commit('STORE_SWAP_TOKO_STAKING', response.data.result.data)

      return response
    })
  },

  rejectSwapTokoStaking({ commit }, data) {
    return SwapTokoService.rejectSwapTokoStaking(data, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.REJECT_SWAP_TOKO_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  approveSwapTokoStaking({ commit }, data) {
    return SwapTokoService.approveSwapTokoStaking(data, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.APPROVE_SWAP_TOKO_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  triggerSwapTokoStakings({ dispatch }) {
    return SwapTokoService.triggerSwapTokoStakings(authorizationHeader()).then(response => {
      lStorage.set('triggerSwapTokoStakings', true)
      dispatch('getSwapTokoStakings', { page: 1, limit: 20 })

      return response
    })
  }
}

export default actions
