// auth
export const STORE_PROFILE = 'STORE_PROFILE'
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA'
export const STORE_ACCESS_TOKEN = 'STORE_ACCESS_TOKEN'

// report
export const STORE_REPORT_BALANCE_SUMMARY = 'STORE_REPORT_BALANCE_SUMMARY'
export const STORE_REPORT_BALANCE_CHART = 'STORE_REPORT_BALANCE_CHART'
export const STORE_REPORT_JOINED_USERS_CHART = 'STORE_REPORT_JOINED_USERS_CHART'
export const STORE_REFERRER_DATA = 'STORE_REFERRER_DATA'
export const STORE_REPORT_REGISTER_CHART = 'STORE_REPORT_REGISTER_CHART'
export const STORE_REPORT_PAYMENT_ORDER_SUMMARY = 'STORE_REPORT_PAYMENT_ORDER_SUMMARY'

// referral
export const STORE_REPORT_REFERRERS = 'STORE_REPORT_REFERRERS'
export const STORE_REPORT_REFERRER = 'STORE_REPORT_REFERRER'
export const STORE_REPORT_REFERRED_USERS = 'STORE_REPORT_REFERRED_USERS'
export const CLEAR_REPORT_REFERRER = 'CLEAR_REPORT_REFERRER'

// program
export const STORE_PROGRAM_LIST = 'STORE_PROGRAM_LIST'
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM'
export const STORE_REPORT_PROGRAM_BALANCES = 'STORE_REPORT_PROGRAM_BALANCES'

// staking
export const STORE_PROGRAM_ID = 'STORE_PROGRAM_ID'
export const STORE_STAKING_USERS = 'STORE_STAKING_USERS'
export const STORE_STAKING_USER = 'STORE_STAKING_USER'
export const CLEAR_STAKING_USER = 'CLEAR_STAKING_USER'
export const STORE_STAKING_PLANS = 'STORE_STAKING_PLANS'
export const STORE_STAKING_PLAN = 'STORE_STAKING_PLAN'
export const STORE_STAKING_INFO = 'STORE_STAKING_INFO'
export const UPDATE_STAKING_INFO = 'UPDATE_STAKING_INFO'
export const STORE_STAKING_SETTINGS = 'STORE_STAKING_SETTINGS'
export const UPDATE_STAKING_SETTING = 'UPDATE_STAKING_SETTING'

// crowndfunding
export const STORE_CROWDFUNDING_INFO = 'STORE_CROWDFUNDING_INFO'
export const UPDATE_CROWDFUNDING_INFO = 'UPDATE_CROWDFUNDING_INFO'
export const STORE_CROWDFUNDING_PLANS = 'STORE_CROWDFUNDING_PLANS'
export const STORE_CROWDFUNDING_PLAN = 'STORE_CROWDFUNDING_PLAN'
export const CLEAR_CROWDFUNDING_PLAN = 'CLEAR_CROWDFUNDING_PLAN'
export const STORE_CROWDFUNDING_JOINED_USERS = 'STORE_CROWDFUNDING_JOINED_USERS'
export const STORE_CROWDFUNDING_JOINED_USER = 'STORE_CROWDFUNDING_JOINED_USER'
export const CLEAR_CROWDFUNDING_JOINED_USER = 'CLEAR_CROWDFUNDING_JOINED_USER'
export const STORE_CROWDFUNDING_FEEDS = 'STORE_CROWDFUNDING_FEEDS'
export const STORE_CROWDFUNDING_FEED = 'STORE_CROWDFUNDING_FEED'
export const CLEAR_CROWDFUNDING_FEED = 'CLEAR_CROWDFUNDING_FEED'

// setting
export const STORE_APP_VERSIONS = 'STORE_APP_VERSIONS'
export const UPDATE_APP_VERSION = 'UPDATE_APP_VERSION'
export const STORE_REFERRAL_SETTING = 'STORE_REFERRAL_SETTING'
export const UPDATE_REFERRAL_SETTING = 'UPDATE_REFERRAL_SETTING'
export const STORE_ADMIN_OF_REFERRAL_SETTING = 'STORE_ADMIN_OF_REFERRAL_SETTING'
export const STORE_CONVERT_CURRENCY = 'STORE_CONVERT_CURRENCY'

// users
export const STORE_USERS = 'STORE_USERS'
export const STORE_USER = 'STORE_USER'
export const STORE_USER_DOCUMENT_CODE_OBJECT = 'STORE_USER_DOCUMENT_CODE_OBJECT'
export const STORE_USER_IDENTIFY_IMAGES = 'STORE_USER_IDENTIFY_IMAGES'
export const STORE_USER_REFERRER = 'STORE_USER_REFERRER'
export const APPROVED_USER = 'APPROVED_USER'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const STORE_EXISTS_USER_DOCUMENTS = 'STORE_EXISTS_USER_DOCUMENTS'
export const CLEAR_EXISTS_USER_DOCUMENTS = 'CLEAR_EXISTS_USER_DOCUMENTS'
export const UPDATE_USER_DOCUMENT_IDENTIFY_CODE = 'UPDATE_USER_DOCUMENT_IDENTIFY_CODE'

// transaction
export const STORE_TRANSACTIONS = 'STORE_TRANSACTIONS'
export const STORE_TRANSACTION = 'STORE_TRANSACTION'
export const CLEAR_TRANSACTION_DATA = 'CLEAR_TRANSACTION_DATA'
export const CLEAR_TRANSACTIONS_DATA = 'CLEAR_TRANSACTIONS_DATA'
export const STORE_TRANSACTIONS_UTILS = 'STORE_TRANSACTIONS_UTILS'
export const CLEAR_TRANSACTIONS_UTILS = 'CLEAR_TRANSACTIONS_UTILS'

// alert
export const STORE_ALERT = 'STORE_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

// d-app
export const STORE_ERC20_ADDRESS = 'STORE_ERC20_ADDRESS'
export const STORE_CURRENT_WALLET_ACCOUNT = 'STORE_CURRENT_WALLET_ACCOUNT'
export const CLEAR_CURRENT_WALLET_ACCOUNT = 'CLEAR_CURRENT_WALLET_ACCOUNT'
export const TOGGLE_DAPP_WAITING = 'TOGGLE_DAPP_WAITING'
export const STORE_CONNECTED_STATUS = 'STORE_CONNECTED_STATUS'

// bo-users
export const STORE_BO_USERS = 'STORE_BO_USERS'
export const DELETE_BO_USER = 'DELETE_BO_USER'
export const UPDATE_BO_USER = 'UPDATE_BO_USER'

// request-transfer
export const STORE_REQUEST_TRANSFERS = 'STORE_REQUEST_TRANSFERS'
export const CLEAR_REQUEST_TRANSFERS = 'CLEAR_REQUEST_TRANSFERS'
export const STORE_REQUEST_TRANSFER = 'STORE_REQUEST_TRANSFER'
export const CLEAR_REQUEST_TRANSFER = 'CLEAR_REQUEST_TRANSFER'
export const APPROVE_REQUEST_TRANSFER = 'APPROVE_REQUEST_TRANSFER'
export const REJECT_REQUEST_TRANSFER = 'REJECT_REQUEST_TRANSFER'

// crowdfunding-admins
export const STORE_CROWDFUNDING_ADMINS = 'STORE_CROWDFUNDING_ADMINS'
export const STORE_CROWDFUNDING_ADMIN = 'STORE_CROWDFUNDING_ADMIN'
export const CLEAR_CROWDFUNDING_ADMINS = 'CLEAR_CROWDFUNDING_ADMINS'
export const CLEAR_CROWDFUNDING_ADMIN = 'CLEAR_CROWDFUNDING_ADMIN'
export const UPDATE_CROWDFUNDING_ADMIN = 'UPDATE_CROWDFUNDING_ADMIN'

// smart-contract
export const STORE_USDT_SMART_CONTRACT = 'STORE_USDT_SMART_CONTRACT'
export const STORE_CF_SMART_CONTRACT = 'STORE_CF_SMART_CONTRACT'
export const STORE_STAKE_V2_SMART_CONTRACT = 'STORE_STAKE_V2_SMART_CONTRACT'
export const STORE_STAKE_SMART_CONTRACT = 'STORE_STAKE_SMART_CONTRACT'

// swap-toko
export const STORE_SWAP_TOKOS = 'STORE_SWAP_TOKOS'
export const CLEAR_SWAP_TOKOS_DATA = 'CLEAR_SWAP_TOKOS_DATA'
export const STORE_SWAP_TOKO = 'STORE_SWAP_TOKO'
export const CLEAR_SWAP_TOKO_DATA = 'CLEAR_SWAP_TOKO_DATA'
export const APPROVE_SWAP_TOKO = 'APPROVE_SWAP_TOKO'
export const REJECT_SWAP_TOKO = 'REJECT_SWAP_TOKO'
export const STORE_SWAP_TOKO_STAKINGS = 'STORE_SWAP_TOKO_STAKINGS'
export const CLEAR_SWAP_TOKO_STAKINGS_DATA = 'CLEAR_SWAP_TOKO_STAKINGS_DATA'
export const STORE_SWAP_TOKO_STAKING = 'STORE_SWAP_TOKO_STAKING'
export const CLEAR_SWAP_TOKO_STAKING_DATA = 'CLEAR_SWAP_TOKO_STAKING_DATA'
export const APPROVE_SWAP_TOKO_STAKING = 'APPROVE_SWAP_TOKO_STAKING'
export const REJECT_SWAP_TOKO_STAKING = 'REJECT_SWAP_TOKO_STAKING'

// payment
export const STORE_PAYMENT_ORDERS = 'STORE_PAYMENT_ORDERS'
export const STORE_PAYMENT_ORDER = 'STORE_PAYMENT_ORDER'
export const CLEAR_PAYMENT_ORDER = 'CLEAR_PAYMENT_ORDER'
export const APPROVE_PAYMENT_ORDER = 'APPROVE_PAYMENT_ORDER'
export const REJECT_PAYMENT_ORDER = 'REJECT_PAYMENT_ORDER'
export const REFUND_PAYMENT_ORDER = 'REFUND_PAYMENT_ORDER'
export const STORE_PAYMENT_TRANSFER_AMOUNT_IMAGE = 'STORE_PAYMENT_TRANSFER_AMOUNT_IMAGE'
export const STORE_PAYMENT_ORDERS_EXPORT_DATA = 'STORE_PAYMENT_ORDERS_EXPORT_DATA'

// convert toko
export const STORE_CONVERT_TOKO_ORDERS = 'STORE_CONVERT_TOKO_ORDERS'
export const STORE_CONVERT_TOKO_ORDER = 'STORE_CONVERT_TOKO_ORDER'
export const STORE_CONVERT_TOKO_TNX_FROM = 'STORE_CONVERT_TOKO_TNX_FROM'
export const STORE_CONVERT_TOKO_TNX_TO = 'STORE_CONVERT_TOKO_TNX_TO'
export const CLEAR_CONVERT_TOKO_ORDER = 'CLEAR_CONVERT_TOKO_ORDER'
export const STORE_CONVERT_TOKO_SETTINGS = 'STORE_CONVERT_TOKO_SETTINGS'
export const UPDATE_CONVERT_TOKO_SETTING = 'UPDATE_CONVERT_TOKO_SETTING'

// gold investment
export const STORE_GOLD_INVESTMENT_INFO = 'STORE_GOLD_INVESTMENT_INFO'
export const UPDATE_GOLD_INVESTMENT_INFO = 'UPDATE_GOLD_INVESTMENT_INFO'
export const STORE_GOLD_INVESTMENT_SETTINGS = 'STORE_GOLD_INVESTMENT_SETTINGS'
export const UPDATE_GOLD_INVESTMENT_SETTING = 'UPDATE_GOLD_INVESTMENT_SETTING'
export const STORE_GOLD_INVESTMENT_REQUIREMENTS = 'STORE_GOLD_INVESTMENT_REQUIREMENTS'
export const UPDATE_GOLD_INVESTMENT_REQUIREMENT = 'UPDATE_GOLD_INVESTMENT_REQUIREMENT'
export const ADD_GOLD_INVESTMENT_REQUIREMENT = 'ADD_GOLD_INVESTMENT_REQUIREMENT'
export const DELETE_GOLD_INVESTMENT_REQUIREMENT = 'DELETE_GOLD_INVESTMENT_REQUIREMENT'
export const STORE_GOLD_INVESTMENT_MARKUP_FEES = 'STORE_GOLD_INVESTMENT_MARKUP_FEES'
export const UPDATE_GOLD_INVESTMENT_MARKUP_FEE = 'UPDATE_GOLD_INVESTMENT_MARKUP_FEE'
export const ADD_GOLD_INVESTMENT_MARKUP_FEE = 'ADD_GOLD_INVESTMENT_MARKUP_FEE'
export const DELETE_GOLD_INVESTMENT_MARKUP_FEE = 'DELETE_GOLD_INVESTMENT_MARKUP_FEE'
export const STORE_GOLD_INVESTMENT_ORDERS = 'STORE_GOLD_INVESTMENT_ORDERS'
export const STORE_GOLD_INVESTMENT_ORDER = 'STORE_GOLD_INVESTMENT_ORDER'
export const CLEAR_GOLD_INVESTMENT_ORDER = 'CLEAR_GOLD_INVESTMENT_ORDER'
export const STORE_GOLD_INVESTMENT_WITHDRAWS = 'STORE_GOLD_INVESTMENT_WITHDRAWS'
export const STORE_GOLD_INVESTMENT_WITHDRAW = 'STORE_GOLD_INVESTMENT_WITHDRAW'
export const CLEAR_GOLD_INVESTMENT_WITHDRAW = 'CLEAR_GOLD_INVESTMENT_WITHDRAW'
export const APPROVE_GOLD_INVESTMENT_WITHDRAW = 'APPROVE_GOLD_INVESTMENT_WITHDRAW'
export const REJECT_GOLD_INVESTMENT_WITHDRAW = 'REJECT_GOLD_INVESTMENT_WITHDRAW'

// request logs
export const STORE_REQUEST_LOGS = 'STORE_REQUEST_LOGS'
export const STORE_REQUEST_LOG = 'STORE_REQUEST_LOG'
export const CLEAR_REQUEST_LOG = 'CLEAR_REQUEST_LOG'
