import moment from '@/moment'
import dataSets from '../../support/data-sets'
import {
  STORE_REQUEST_TRANSFERS,
  CLEAR_REQUEST_TRANSFERS,
  STORE_REQUEST_TRANSFER,
  CLEAR_REQUEST_TRANSFER,
  APPROVE_REQUEST_TRANSFER,
  REJECT_REQUEST_TRANSFER,
  STORE_CROWDFUNDING_ADMINS,
  CLEAR_CROWDFUNDING_ADMINS,
  STORE_CROWDFUNDING_ADMIN,
  CLEAR_CROWDFUNDING_ADMIN
} from '../mutation-types'
import { CF_REQUEST_TRANSFER_STATUS } from '@/utils/key-mapping'

const mutations = {
  [STORE_REQUEST_TRANSFERS](state, payload) {
    state.requestTransfers = payload
  },

  [CLEAR_REQUEST_TRANSFERS](state) {
    state.requestTransfers = null
  },

  [STORE_REQUEST_TRANSFER](state, payload) {
    state.requestTransfer = payload
  },

  [CLEAR_REQUEST_TRANSFER](state) {
    state.requestTransfer = null
  },

  [APPROVE_REQUEST_TRANSFER](state) {
    state.requestTransfer.status = CF_REQUEST_TRANSFER_STATUS.approveInitial
    state.requestTransfer.approve_at = moment().format('LLL')
  },

  [REJECT_REQUEST_TRANSFER](state) {
    state.requestTransfer.status = CF_REQUEST_TRANSFER_STATUS.rejected
    state.requestTransfer.reject_at = moment().format('LLL')
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initWithdrawAssets
  },

  [STORE_CROWDFUNDING_ADMINS](state, payload) {
    state.crowdfundingAdmins = payload
  },

  [CLEAR_CROWDFUNDING_ADMINS](state) {
    state.crowdfundingAdmins = null
  },

  [STORE_CROWDFUNDING_ADMIN](state, payload) {
    state.crowdfundingAdmin = payload
  },

  [CLEAR_CROWDFUNDING_ADMIN](state) {
    state.crowdfundingAdmin = null
  }
}

export default mutations
