import moment from '@/moment'
import { GROUP_BY_OPTIONS } from '@/utils/data-sets'

class RestrictGroupByOptions {
  constructor(dateContext) {
    this.startDate = moment(dateContext.startDate)
    this.endDate = moment(dateContext.endDate)
    this.initData = {
      aDay: 1,
      aWeek: 7,
      aMonth: 31,
      aQuarter: 120,
      aYear: 366
    }
    this.optionValues = GROUP_BY_OPTIONS
  }

  numberOfDaysBetween() {
    return this.endDate.diff(this.startDate, 'days')
  }

  isSameYear() {
    return this.startDate.isSame(this.endDate, 'year')
  }

  getHourlyValue() {
    return this.optionValues[0].value
  }

  getDailyValue() {
    return this.optionValues[1].value
  }

  getWeeklyValue() {
    return this.optionValues[2].value
  }

  getMonthyValue() {
    return this.optionValues[3].value
  }

  getQuarterlyValue() {
    return this.optionValues[4].value
  }

  getYearlyValue() {
    return this.optionValues[5].value
  }

  getEnableGroupByOptions() {
    if (this.numberOfDaysBetween() < this.initData.aDay) {
      return [this.getHourlyValue()]
    }

    if (this.numberOfDaysBetween() < this.initData.aWeek) {
      return [this.getDailyValue()]
    }

    if (this.numberOfDaysBetween() < this.initData.aMonth) {
      return [this.getDailyValue(), this.getWeeklyValue()]
    }

    if (this.numberOfDaysBetween() < this.initData.aQuarter) {
      return [this.getWeeklyValue(), this.getMonthyValue()]
    }

    if (this.numberOfDaysBetween() < this.initData.aYear && this.isSameYear()) {
      return [this.getMonthyValue(), this.getQuarterlyValue()]
    }

    return [this.getMonthyValue(), this.getQuarterlyValue(), this.getYearlyValue()]
  }
}

export default function formatGroupByOptions(dateRange) {
  const enabledValues = new RestrictGroupByOptions(dateRange).getEnableGroupByOptions()
  const groupByOptions = [...GROUP_BY_OPTIONS].map(option => {
    option.disabled = enabledValues.indexOf(option.value) === -1

    return option
  })

  return {
    options: groupByOptions,
    enabledValues
  }
}
