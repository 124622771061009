import CrowdFunding from '@/contracts/CrowdFunding'
import Web3 from 'web3'
import { generateOffchainByte, getAmountWithDecimal, getCfRequestTransferTnxRequest } from '@/utils/helper'
import { CF_REQUEST_TRANSFER_TNX_OPTIONS_TYPE } from '@/utils/key-mapping'
import * as message from '../../support/message'
let cfSmartContract
let cfContractInstance
let web3Instace

const actions = {
  async getWeb3({ commit }) {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum)
      try {
        // Request account access if needed
        await window.ethereum.enable()
        // Acccounts now exposed
        web3Instace = web3
      } catch (error) {
        commit('alert/STORE_ALERT', { type: 'error', content: error.message, timeOut: 2400 }, { root: true })
      }
    } else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3
      web3Instace = web3
    } else {
      commit('STORE_CONNECTED_STATUS', false)
      commit(
        'alert/STORE_ALERT',
        { type: 'error', content: message.METAMASK_NOT_INSTALLED, timeOut: 2400 },
        { root: true }
      )
    }
  },

  async web3Connect({ dispatch, commit }) {
    if (web3Instace || cfContractInstance) return commit('STORE_CONNECTED_STATUS', true)
    await dispatch('getWeb3')
    if (!web3Instace) return commit('STORE_CONNECTED_STATUS', false)

    // init smart contract instance
    await dispatch('getContractInstance')
    // get account
    await dispatch('getDappAccount')
    // isConnected = true
    commit('STORE_CONNECTED_STATUS', true)
  },

  async getDappAccount({ commit, dispatch }) {
    const accounts = await web3Instace.eth.getAccounts()
    if (accounts.length > 0) {
      const account = accounts[0]
      commit('STORE_CURRENT_WALLET_ACCOUNT', account)
      commit('STORE_CONNECTED_STATUS', true)
      // observer web3 provider
      dispatch('observerProvider')
    } else {
      commit(
        'alert/STORE_ALERT',
        { type: 'error', content: message.METAMASK_NOT_CONNECTED, timeOut: 2400 },
        { root: true }
      )
    }
  },

  async getContractInstance({ rootState }) {
    cfSmartContract = rootState.smartContract.cfSmartContract
    cfContractInstance = await new web3Instace.eth.Contract(CrowdFunding.abi, cfSmartContract.address, {})
  },

  requestTransferByAdmin({ commit, state, dispatch, rootState }, data) {
    // step 1: make sure cfContractInsace exist
    if (!web3Instace) return commit('STORE_CONNECTED_STATUS', false)

    // step 2: handle data include pow amount with symbol, generate offchain
    commit('TOGGLE_DAPP_WAITING')
    const { offchain, amount, token } = data
    const offchainByte = generateOffchainByte(offchain)
    const erc20Amount = getAmountWithDecimal(amount, token.decimal)
    const erc20TokenAddress = rootState.smartContract.usdtSmartContract.address

    // step 3: call metamask
    cfContractInstance.methods
      .requestTransferByAdmin(erc20TokenAddress, state.account, erc20Amount, offchainByte)
      .send({ from: state.account })
      .on('transactionHash', hash => {
        const tnxDataRequest = getCfRequestTransferTnxRequest(
          hash,
          state.account,
          data,
          cfSmartContract,
          CF_REQUEST_TRANSFER_TNX_OPTIONS_TYPE.request
        )

        commit('TOGGLE_DAPP_WAITING')
        dispatch('transaction/addTransaction', tnxDataRequest, { root: true })
      })
      // eslint-disable-next-line no-unused-vars
      .on('error', (err, receipt) => {
        // return error message if metamask not connected
        if (err.code === 4001) {
          commit('TOGGLE_DAPP_WAITING')
          commit('alert/STORE_ALERT', { type: 'error', content: err.message, timeOut: 2400 }, { root: true })
        }
      })
  },

  approvedRequestTransferByAdmin({ commit, state, dispatch }, data) {
    // step 1: make sure cfContractInsace exist
    if (!web3Instace) commit('STORE_CONNECTED_STATUS', false)

    // step 2: generate offchain
    commit('TOGGLE_DAPP_WAITING')
    const { offchain, r_id } = data.requestData
    const offchainByte = generateOffchainByte(offchain)

    cfContractInstance.methods
      .approveTransferRequestByAdmin(parseInt(r_id), offchainByte)
      .send({ from: state.account })
      .on('transactionHash', async hash => {
        const tnxDataRequest = getCfRequestTransferTnxRequest(
          hash,
          state.account,
          data.requestData,
          cfSmartContract,
          CF_REQUEST_TRANSFER_TNX_OPTIONS_TYPE.approve
        )

        commit('TOGGLE_DAPP_WAITING')
        await dispatch('requestTransfer/approveRequestTransfer', data, { root: true })
        await dispatch('transaction/addTransaction', tnxDataRequest, { root: true })
      })
      // eslint-disable-next-line no-unused-vars
      .on('error', (err, receipt) => {
        // return error message if metamask not connected
        if (err.code === 4001) {
          commit('TOGGLE_DAPP_WAITING')
          commit('alert/STORE_ALERT', { type: 'error', content: err.message, timeOut: 2400 }, { root: true })
        }
      })
  },

  /*
    observer
  */
  observerProvider({ commit, dispatch }) {
    if (!web3Instace) commit('STORE_CONNECTED_STATUS', false)
    dispatch('handleAccountsChanged')
  },

  handleAccountsChanged({ commit, state }) {
    web3Instace.currentProvider.on('accountsChanged', function(accounts) {
      if (accounts.length === 0) {
        commit('CLEAR_CURRENT_WALLET_ACCOUNT')
        commit(
          'alert/STORE_ALERT',
          { type: 'error', content: message.METAMASK_NOT_CONNECTED, timeOut: 2400 },
          { root: true }
        )
      } else if (accounts[0] !== state.account) {
        commit('STORE_CURRENT_WALLET_ACCOUNT', accounts[0])
      }
    })
  }
}

export default actions
