const getters = {
  stakingInterestRates: (state, getters, rootState) => {
    if (!rootState.staking.stakingPlans.plans.length) return

    const stakingPlans = rootState.staking.stakingPlans
    // eslint-disable-next-line no-unused-vars
    const stakingInterests = stakingPlans.plans.reduce((acc, currentValue, currentIndex) => {
      for (let i = 0; i < currentValue.interests.length; i++) {
        currentValue.interests[i].planName = currentValue.title
        acc.push(currentValue.interests[i])
      }
      return acc
    }, [])

    return stakingInterests
  },

  formatSwapTokoStakingsData: (state, getters) => {
    if (!state.swapTokoStakings) return

    const interests = getters.stakingInterestRates
    return state.swapTokoStakings.data.map(item => {
      const target = interests.filter(interest => interest.id === item?.stakingOrder?.planInterestId)
      item.feStakingData = target[0] || []

      return item
    })
  },

  formatSwapTokoStakingData: (state, getters) => {
    if (!state.swapTokoStaking) return

    const swapData = state.swapTokoStaking
    const interests = getters.stakingInterestRates
    const target = interests.filter(interest => interest.id === swapData?.stakingOrder?.planInterestId)
    swapData.feStakingData = target[0] || []

    return swapData
  }
}

export default getters
