import dataSets from '../../support/data-sets'

import {
  STORE_TRANSACTIONS,
  STORE_TRANSACTION,
  CLEAR_TRANSACTIONS_DATA,
  CLEAR_TRANSACTION_DATA
} from '../mutation-types'

const mutations = {
  [STORE_TRANSACTIONS](state, payload) {
    state.transactions = payload
  },

  [STORE_TRANSACTION](state, payload) {
    state.transaction = payload
  },

  [CLEAR_TRANSACTIONS_DATA](state) {
    state.transactions = null
  },

  [CLEAR_TRANSACTION_DATA](state) {
    state.transaction = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initTransactionAssets
  }
}

export default mutations
