import {
  CF_REQUEST_TRANSFER_STATUS,
  SWAP_TOKO_STAKING_STATUS,
  SWAP_TOKO_USER_TRANSFER_STATUS,
  TNX_STATUS,
  TNX_NETWORK_TYPES
} from '@/utils/key-mapping'

const initUsersAssets = {
  searchText: '',
  page: 1,
  params: {
    verified: 0,
    perPage: 20,
    sortBy: 'updated_at'
  }
}

const initStakingUsersAssets = {
  params: {
    stakingPlan: 0,
    perPage: 20,
    status: 0,
    tokoVer: 2
  },
  searchText: '',
  page: 1
}

const initReferrersAssets = {
  params: {
    perPage: 20,
    sortBy: 'credit_toko'
  },
  page: 1,
  searchText: ''
}

const initTransactionAssets = {
  params: {
    perPage: 20,
    address: null,
    tnxHash: null,
    optionsDataType: 0,
    contractAddress: '',
    status: TNX_STATUS.invalid,
    networkType: TNX_NETWORK_TYPES.invalid
  },
  searchParams: {
    searchTarget: 0,
    searchText: ''
  },
  filterValues: [],
  page: 1
}

const initWithdrawAssets = {
  params: {
    perPage: 10,
    status: CF_REQUEST_TRANSFER_STATUS.invalid
  },
  page: 1
}

const initSwapTokoAssets = {
  params: {
    perPage: 20,
    status: SWAP_TOKO_USER_TRANSFER_STATUS.invalid,
    address: null,
    tnxHash: null
  },
  searchParams: {
    searchTarget: 0,
    searchText: ''
  },
  page: 1
}

const initSwapTokoStakingAssets = {
  params: {
    perPage: 20,
    status: SWAP_TOKO_STAKING_STATUS.invalid
  },
  search: '',
  page: 1
}

const initCrowdfundingUsersAssets = {
  params: {
    plan: 0,
    perPage: 20,
    status: 0
  },
  page: 1
}

const initCrowdfundingFeedsAssets = {
  params: {
    perPage: 10,
    address: null,
    email: null
  },
  searchParams: {
    searchText: '',
    searchTarget: 0
  },
  page: 1
}

const initPaymentOrderAssets = {
  filterValues: [],
  searchParams: {
    searchText: '',
    searchTarget: 0
  },
  params: {
    perPage: 10
  },
  page: 1
}

const initGoldInvestmentOrderAssets = {
  filterValues: [],
  searchParams: {
    searchText: '',
    searchTarget: 0
  },
  params: {
    perPage: 10
  },
  page: 1
}

const initGoldInvestmentWithdrawAssets = {
  filterValues: [],
  searchParams: {
    searchText: '',
    searchTarget: 0
  },
  params: {
    perPage: 10
  },
  page: 1
}

const initConvertTokoAssets = {
  filterValues: [],
  searchParams: {
    searchText: '',
    searchTarget: 0
  },
  params: {
    perPage: 10,
    networkType: TNX_NETWORK_TYPES.invalid
  },
  page: 1
}

const initRequestLogAssets = {
  filterValues: [],
  params: {
    perPage: 10,
    networkType: TNX_NETWORK_TYPES.invalid,
    type: 0
  },
  page: 1
}

export default {
  initTransactionAssets,
  initUsersAssets,
  initStakingUsersAssets,
  initReferrersAssets,
  initWithdrawAssets,
  initSwapTokoAssets,
  initSwapTokoStakingAssets,
  initCrowdfundingUsersAssets,
  initCrowdfundingFeedsAssets,
  initPaymentOrderAssets,
  initGoldInvestmentOrderAssets,
  initGoldInvestmentWithdrawAssets,
  initConvertTokoAssets,
  initRequestLogAssets
}
