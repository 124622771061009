import dataSets from '../../support/data-sets'

const state = {
  stakingInfo: null,
  programId: null,
  stakingUsers: null,
  stakingUser: null,
  stakingPlans: {
    plans: [],
    total: null
  },
  stakingPlan: null,
  assets: dataSets.initStakingUsersAssets,
  stakingSettings: null
}

export default state
