import SwapTokoService from '@/services/swap-toko'
import { authorizationHeader } from '@/store/support/authorization'
import * as message from '@/store/support/message'

const actions = {
  getSwapTokos({ commit }, params) {
    return SwapTokoService.getSwapTokos(params, authorizationHeader()).then(response => {
      commit('STORE_SWAP_TOKOS', response.data.result)

      return response
    })
  },

  getSwapToko({ commit }, id) {
    return SwapTokoService.getSwapToko(id, authorizationHeader()).then(response => {
      commit('STORE_SWAP_TOKO', response.data.result.data)

      return response
    })
  },

  rejectSwapToko({ commit }, data) {
    return SwapTokoService.rejectSwapToko(data, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.REJECT_SWAP_TOKO_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  approveSwapToko({ commit }, data) {
    return SwapTokoService.approveSwapToko(data, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.APPROVE_SWAP_TOKO_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  }
}

export default actions
