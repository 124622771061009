import dataSets from '../../support/data-sets'

const state = {
  users: null,
  user: null,
  userReferrer: null,
  userDocumentCodeObj: null,
  existsUserDocuments: null,
  userIdentifyImages: null,
  assets: dataSets.initUsersAssets
}

export default state
