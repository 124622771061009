import apiCaller from './api-caller'

const RESOURCES = {
  crowdfunding: '/program/crowdfunding',
  report: '/report/crowdfunding',
  feed: '/crowdfunding/feed'
}

export default {
  getCrowdfundingPlans(id, params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.crowdfunding}/${id}/interest-rates`, params, headers })
  },

  getCrowdfundingPlan(programId, planId, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.crowdfunding}/${programId}/interest-rate/${planId}`, headers })
  },

  addCrowdfundingPlan(programId, data, headers) {
    return apiCaller({
      method: 'POST',
      url: `${RESOURCES.crowdfunding}/${programId}`,
      data,
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  updateCrowdfundingPlan(programId, planId, data, headers) {
    return apiCaller({
      method: 'PUT',
      url: `${RESOURCES.crowdfunding}/${programId}/interest-rate/${planId}`,
      data,
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  deleteCrowdfundingPlan(programId, planId, params, headers) {
    return apiCaller({
      method: 'DELETE',
      url: `${RESOURCES.crowdfunding}/${programId}/interest-rate/${planId}`,
      params,
      headers
    })
  },

  getCrowdfundingUsers(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.report}/users`, params, headers })
  },

  getCrowdfundingFeeds(params, headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.feed, params, headers })
  },

  getCrowdfundingFeed(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.feed}/${id}`, headers })
  }
}
