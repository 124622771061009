import Page from './page'

const classes = {
  Page
}

export default function authorization(model, currentUser, record) {
  if (!classes[model]) throw new Error(`${model} class in authorization is not exists!`)
  return new classes[model](currentUser, record)
}
