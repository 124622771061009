import dataSets from '../../support/data-sets'

const state = {
  crowdfundingInfo: null,
  plans: null,
  plan: null,
  joinedUsers: null,
  joinedUser: null,
  feeds: null,
  feed: null,
  assets: dataSets.initCrowdfundingUsersAssets,
  feedAssets: dataSets.initCrowdfundingFeedsAssets
}

export default state
