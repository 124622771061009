import dataSets from '../../support/data-sets'
import {
  STORE_USERS,
  STORE_USER,
  STORE_USER_DOCUMENT_CODE_OBJECT,
  UPDATE_USER_DOCUMENT_IDENTIFY_CODE,
  STORE_USER_IDENTIFY_IMAGES,
  STORE_USER_REFERRER,
  STORE_EXISTS_USER_DOCUMENTS,
  CLEAR_EXISTS_USER_DOCUMENTS,
  APPROVED_USER,
  CLEAR_USER_DATA
} from '../mutation-types'

const mutations = {
  [STORE_USERS](state, payload) {
    state.users = payload
  },

  [STORE_USER](state, payload) {
    state.user = payload
  },

  [STORE_USER_DOCUMENT_CODE_OBJECT](state, payload) {
    state.userDocumentCodeObj = payload
  },

  [UPDATE_USER_DOCUMENT_IDENTIFY_CODE](state, payload) {
    state.userDocumentCodeObj.document_code = payload
  },

  [STORE_USER_IDENTIFY_IMAGES](state, payload) {
    state.userIdentifyImages = payload
  },

  [STORE_USER_REFERRER](state, payload) {
    state.userReferrer = payload
  },

  [STORE_EXISTS_USER_DOCUMENTS](state, payload) {
    state.existsUserDocuments = payload
  },

  [CLEAR_EXISTS_USER_DOCUMENTS](state) {
    state.existsUserDocuments = null
  },

  [APPROVED_USER](state) {
    state.user.document_verification = 2
  },

  [CLEAR_USER_DATA](state) {
    state.user = null
    state.userIdentifyImages = null
    state.existsUserDocuments = null
    state.userDocumentCodeObj = null
    state.userReferrer = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initUsersAssets
  }
}

export default mutations
