import { deepCopy } from '@/utils/json-parser'
import { plansOrdered } from '../../support/utils'

const getters = {
  getStakingPlansOrdered: state => {
    const planOrderedArgs = {
      lStoreKey: 'stakingPlanIdsOrdered',
      plans: deepCopy(state.stakingPlans.plans)
    }

    return plansOrdered(planOrderedArgs)
  },

  stakingPlanOptions: state => {
    const ALL_PLANS_OPTION = { value: 0, name: 'All Plans', disabled: false }
    const options = state.stakingPlans.plans.map(option => {
      return {
        value: option.id,
        name: option.title,
        disabled: false
      }
    })

    return [ALL_PLANS_OPTION, ...options]
  }
}

export default getters
