import {
  CF_REQUEST_TRANSFER_STATUS,
  SWAP_TOKO_STAKING_STATUS,
  SWAP_TOKO_USER_TRANSFER_STATUS,
  PAYMENT_ORDER_STATUS,
  PAYMENT_CRYPTO_TYPE,
  TNX_NETWORK_TYPES,
  TNX_STATUS,
  CONVERT_TOKO_STATUS,
  REQUEST_LOG_STATUSES,
  REQUEST_LOG_TYPES
} from './key-mapping'

// options filter
export const GROUP_BY_OPTIONS = [
  { value: 1, name: 'Hourly', disabled: true },
  { value: 2, name: 'Daily', disabled: true },
  { value: 3, name: 'Weekly', disabled: true },
  { value: 4, name: 'Monthly', disabled: true },
  { value: 5, name: 'Quarterly', disabled: true },
  { value: 6, name: 'Yearly', disabled: true }
]

export const CHART_TYPE_OPTIONS = [
  { value: 1, name: 'Bar', disabled: false },
  { value: 0, name: 'Line', disabled: false }
]

export const PAGER_OPTIONS = [
  { value: 10, name: '10 Per Page', disabled: false },
  { value: 20, name: '20 Per Page', disabled: false },
  { value: 50, name: '50 Per Page', disabled: false },
  { value: 100, name: '100 Per Page', disabled: false }
]

export const ACCOUNT_STATUS_OPTIONS = [
  { value: 2, name: 'All Status', disabled: false },
  { value: 0, name: 'Needs Verification', disabled: false },
  { value: 1, name: 'Already Verified', disabled: false }
]

export const STAKING_USERS_STATUS_OPTIONS = [
  { value: 0, name: 'All Status', disabled: false },
  { value: 3, name: 'Active', disabled: false },
  { value: 7, name: 'Withdrawn', disabled: false },
  { value: 5, name: 'Initial Withdraw', disabled: false },
  { value: 6, name: 'Pending Withdraw', disabled: false },
  { value: 8, name: 'Failed Withdraw', disabled: false }
]

export const TRANSACTION_SEARCH_TARGET_OPTIONS = [
  { value: 1, name: 'Wallet Address', disabled: false },
  { value: 0, name: 'Transaction Hash', disabled: false }
]

export const TRANSACTION_STATUS_OPTIONS = [
  { value: 0, name: 'All Status', disabled: false },
  { value: 1, name: 'Pending', disabled: false },
  { value: 2, name: 'Success', disabled: false },
  { value: 3, name: 'Failed', disabled: false },
  { value: 4, name: 'Error', disabled: false }
]

export const WITHDRAW_STATUS_OPTIONS = [
  { value: CF_REQUEST_TRANSFER_STATUS.invalid, name: 'All Statuses', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.requestInitial, name: 'Initial Request', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.requestPending, name: 'Pending Request', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.requestSuccess, name: 'Need Review', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.requestFailed, name: 'Failed Request', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.rejected, name: 'Rejected', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.approveInitial, name: 'Initial Approve', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.approvePending, name: 'Pending Approve', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.approveSuccess, name: 'Approved', disabled: false },
  { value: CF_REQUEST_TRANSFER_STATUS.approveFailed, name: 'Failed Approve', disabled: false }
]

export const SWAP_TOKO_CLAIM_STAKING_OPTIONS = [
  { value: SWAP_TOKO_STAKING_STATUS.invalid, name: 'All', disabled: false },
  { value: SWAP_TOKO_STAKING_STATUS.claimInit, name: 'Need Review', disabled: false },
  { value: SWAP_TOKO_STAKING_STATUS.adminReject, name: 'Rejected', disabled: false },
  { value: SWAP_TOKO_STAKING_STATUS.adminApprove, name: 'Approve Initial', disabled: false },
  { value: SWAP_TOKO_STAKING_STATUS.adminTransferPending, name: 'Transfered Pending', disabled: false },
  { value: SWAP_TOKO_STAKING_STATUS.adminTransferSuccess, name: 'Transfered Success', disabled: false },
  { value: SWAP_TOKO_STAKING_STATUS.adminTransferFailed, name: 'Transfered Failed', disabled: false }
]

export const SWAP_TOKO_USER_TRANSFER_OPTIONS = [
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.invalid, name: 'All', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.userTransferInit, name: 'User Transfer Inital', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.userTransferPending, name: 'User Transfer Pending', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.userTransferSuccess, name: 'Need Review', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.userTransferFailed, name: 'User Transfer Failed', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.adminReject, name: 'Rejected', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.adminApprove, name: 'Approve Initial', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.adminTransferPending, name: 'Approve Pending', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.adminTransferSuccess, name: 'Approved', disabled: false },
  { value: SWAP_TOKO_USER_TRANSFER_STATUS.adminTransferFailed, name: 'Approve Failed', disabled: false }
]

export const STAKING_TOKO_OPTIONS = [
  { value: 0, name: 'All version', category: 'tokoVersion' },
  { value: 1, name: 'TOKO ver.1', category: 'tokoVersion' },
  { value: 2, name: 'TOKO ver.2', category: 'tokoVersion' }
]

export const SWAP_TOKO_OPTIONS = [
  { value: 0, name: 'All', category: 'swapBy' },
  { value: 1, name: 'User Transafer', category: 'swapBy' },
  { value: 2, name: 'Admin Transfer', category: 'swapBy' }
]

export const PAYMENT_ORDER_STATUS_OPTIONS = [
  { value: PAYMENT_ORDER_STATUS.initial, name: 'Need review', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.failed, name: 'Failed', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.paid, name: 'Paid', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.reject, name: 'Rejected', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.approve, name: 'Approved', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.success, name: 'Success', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.cancel, name: 'Cancel', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.refund, name: 'Refund', category: 'status' },
  { value: PAYMENT_ORDER_STATUS.expire, name: 'Expire', category: 'status' }
]

export const CONVERT_TOKO_ORDER_STATUS_OPTIONS = [
  { value: CONVERT_TOKO_STATUS.initial, name: 'Initial', category: 'status' },
  { value: CONVERT_TOKO_STATUS.sendPending, name: 'Send TOKO Pending', category: 'status' },
  { value: CONVERT_TOKO_STATUS.sendFailed, name: 'Send TOKO Failed', category: 'status' },
  { value: CONVERT_TOKO_STATUS.sendSuccess, name: 'Send TOKO Success', category: 'status' },
  { value: CONVERT_TOKO_STATUS.sendCancel, name: 'Send TOKO Cancel', category: 'status' },
  { value: CONVERT_TOKO_STATUS.receivePending, name: 'Receive TOKO Pending', category: 'status' },
  { value: CONVERT_TOKO_STATUS.receiveFailed, name: 'Receive TOKO Failed', category: 'status' },
  { value: CONVERT_TOKO_STATUS.receiveSuccess, name: 'Receive TOKO Success', category: 'status' }
]

export const PAYMENT_CRYPTO_TYPE_DATA = [
  { type: PAYMENT_CRYPTO_TYPE.invalid, name: 'INVALID' },
  { type: PAYMENT_CRYPTO_TYPE.toko, name: 'TOKO' },
  { type: PAYMENT_CRYPTO_TYPE.btc, name: 'BTC' },
  { type: PAYMENT_CRYPTO_TYPE.eth, name: 'ETH' }
]

export const TRANSACTION_STATUS_FILTER_OPTIONS = [
  { value: TNX_STATUS.invalid, name: 'All Status', category: 'tnxStatus' },
  { value: TNX_STATUS.pending, name: 'Pending', category: 'tnxStatus' },
  { value: TNX_STATUS.success, name: 'Success', category: 'tnxStatus' },
  { value: TNX_STATUS.failed, name: 'Failed', category: 'tnxStatus' },
  { value: TNX_STATUS.error, name: 'Error', category: 'tnxStatus' },
  { value: TNX_STATUS.notFound, name: 'Not Found', category: 'tnxStatus' },
  { value: TNX_STATUS.btcUnconfirmed, name: 'Unconfirmed', category: 'tnxStatus' }
]

export const TNX_NETWORK_TYPE_OPTIONS = [
  { value: TNX_NETWORK_TYPES.invalid, name: 'All Network', category: 'networkType' },
  { value: TNX_NETWORK_TYPES.ethereum, name: 'Ethereum Network', category: 'networkType' },
  { value: TNX_NETWORK_TYPES.bsc, name: 'BSC Network', category: 'networkType' },
  { value: TNX_NETWORK_TYPES.btc, name: 'BTC Network', category: 'networkType' }
]

export const REQUEST_LOG_STATUS_OPTIONS = [
  { value: REQUEST_LOG_STATUSES.invalid, name: 'All Status', category: 'status' },
  { value: REQUEST_LOG_STATUSES.parseJson, name: 'Parse Json', category: 'status' },
  { value: REQUEST_LOG_STATUSES.waitingAdmin, name: 'Waiting Admin Free', category: 'status' },
  { value: REQUEST_LOG_STATUSES.transferERC20, name: 'Transfer ERC20', category: 'status' },
  { value: REQUEST_LOG_STATUSES.dialAssetService, name: 'Dial Assets Service', category: 'status' },
  { value: REQUEST_LOG_STATUSES.dialUserService, name: 'Dial User Service', category: 'status' },
  { value: REQUEST_LOG_STATUSES.fail, name: 'Fail', category: 'status' },
  { value: REQUEST_LOG_STATUSES.dialEventService, name: 'Dial Event Service', category: 'status' },
  { value: REQUEST_LOG_STATUSES.addFeedOracle, name: 'Add Feed Oracle', category: 'status' },
  { value: REQUEST_LOG_STATUSES.updateInterestRate, name: 'Update Interest Rate', category: 'status' },
  { value: REQUEST_LOG_STATUSES.dialProgramService, name: 'Dial Program Service', category: 'status' },
  { value: REQUEST_LOG_STATUSES.convertTokoTransfer, name: 'Convert Toko Transfer', category: 'status' }
]

export const REQUEST_LOG_TYPE_OPTIONS = [
  { value: REQUEST_LOG_TYPES.invalid, name: 'All Type', category: 'type' },
  { value: REQUEST_LOG_TYPES.referral, name: 'Referral', category: 'type' },
  { value: REQUEST_LOG_TYPES.doubleToko, name: 'Double Toko', category: 'type' },
  { value: REQUEST_LOG_TYPES.crowdfundingFeed, name: 'Crowdfunding Feed', category: 'type' },
  { value: REQUEST_LOG_TYPES.crowdfundingInterestRate, name: 'Crowdfunding Interest Rate', category: 'type' },
  { value: REQUEST_LOG_TYPES.swapToko, name: 'Swap Toko', category: 'type' },
  { value: REQUEST_LOG_TYPES.paymentTransfer, name: 'Payment Transfer', category: 'type' },
  { value: REQUEST_LOG_TYPES.convertTokoTransfer, name: 'Convert Toko', category: 'type' }
]

// group navigation
export const DASHBOARD_GROUP_NAVIGATION = [
  { text: 'Staking', name: 'DashboardStaking', exact: true },
  // { text: 'Crowdfunding', name: 'DashboardCrowdfunding' },
  // { text: 'Referral', name: 'DashboardReferral' },
  { text: 'User Register', name: 'DashboardUserRegister' }
]

export const USER_GROUP_NAVIGATION = [
  { text: 'All Users', name: 'Users' },
  { text: 'Staking', name: 'StakingUsers' },
  { text: 'Crowdfunding', name: 'CrowdfundingUsers' },
  { text: 'Referral', name: 'UserReferrers' }
]

export const PROGRAM_GROUP_NAVIGATION = [
  { text: 'Staking', name: 'ProgramsStaking' },
  { text: 'BISS Program', name: 'CrowdfundingPlans' }
  // { text: 'Gold Investment', name: 'GoldInvestment' }
]

export const TRANSACTION_GROUP_NAVIGATION = [
  { text: 'All Transactions', name: 'Transactions' },
  { text: 'Wrong MetaData', name: 'TransactionsErrorActions' },
  { text: 'Event', name: 'TransactionsEvent' },
  { text: 'Referral', name: 'TransactionsReferral' },
  { text: 'Transfer ETH. ERC20', name: 'TransactionsERC20' },
  { text: 'Staking', name: 'TransactionsStaking' },
  { text: 'Swap Toko', name: 'TransactionsSwapToko' },
  { text: 'Crowdfunding', name: 'TransactionsCrowdfundingOrder' },
  { text: 'Buy Cryptos', name: 'PaymentOrders' }
  // { text: 'Convert Toko', name: 'ConvertTokoOrders' }
  // { text: 'Gold Investment', name: 'GoldInvestmentTnxs' },
  // { text: 'Double TOKO Event', name: 'TransactionsDoubleToko' },
]

export const SWAP_TOKO_GROUP_NAVIGATION = [
  { text: 'Staking Plans', name: 'SwapClaimBonus' },
  { text: 'Sent Transactions', name: 'SwapUserTransfers' }
]

// options for support chart
export const COMMON_LINE_CHART_OPTIONS = {
  pointBorderColor: '#fff',
  pointBorderWidth: 2,
  pointRadius: 6,
  borderWidth: 2,
  backgroundColor: 'transparent'
}

export const COMMON_BAR_CHART_OPTIONS = {
  borderWidth: 1,
  barPercentage: 0.3,
  minBarLength: 2
}

export const REPORT_DATA_SETS = [
  {
    valueClass: 'headline--xl text-blue',
    borderColor: '#20409a',
    pointBackgroundColor: '#20409a'
  },
  {
    valueClass: 'headline--xl text-warm',
    borderColor: '#45414b',
    pointBackgroundColor: '#45414b'
  },
  {
    valueClass: 'headline--xl text-pink',
    borderColor: '#f5616d',
    pointBackgroundColor: '#f5616d'
  },
  {
    valueClass: 'headline--xl text-light-purple',
    borderColor: '#9663b2',
    pointBackgroundColor: '#9663b2'
  },
  {
    valueClass: 'headline--xl text-warning',
    borderColor: '#ffb71b',
    pointBackgroundColor: '#ffb71b'
  },
  {
    valueClass: 'headline--xl text-success',
    borderColor: '#31d736',
    pointBackgroundColor: '#31d736'
  },
  {
    valueClass: 'headline--xl text-ocean',
    borderColor: '#3bb8e5',
    pointBackgroundColor: '#3bb8e5'
  },
  {
    valueClass: 'headline--xl text-primary',
    borderColor: '#8361d2',
    pointBackgroundColor: '#8361d2'
  },
  {
    valueClass: 'headline--xl text-error',
    borderColor: '#d41c1c',
    pointBackgroundColor: '#d41c1c'
  }
]

// csv options
export const USERS_CSV_FIELDS = ['id', 'username', 'full_name', 'email', 'country_code', 'phone', 'referral_code']

export const USERS_CSV_LABELS = ['id', 'username', 'full_name', 'email', 'country_code', 'phone', 'referral_code']
