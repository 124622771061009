import { authorizationHeader } from '@/store/support/authorization'
import RequestTransferService from '@/services/request-transfer'
import * as message from '@/store/support/message'

const actions = {
  getRequestTransfers({ commit }, params) {
    return RequestTransferService.getRequestTransfers(params, authorizationHeader()).then(response => {
      commit('STORE_REQUEST_TRANSFERS', response.data.result)

      return response
    })
  },

  getRequestTransfer({ commit }, id) {
    return RequestTransferService.getRequestTransfer(id, authorizationHeader()).then(response => {
      commit('STORE_REQUEST_TRANSFER', response.data.result.data)

      return response
    })
  },

  addRequestTransfer({ dispatch, rootState }, data) {
    const dataRequest = {
      amount: parseInt(data.amount),
      requestNote: data.requestNote,
      tokenId: rootState.smartContract.usdtSmartContract.id,
      smartContractId: rootState.smartContract.cfSmartContract.id
    }
    return RequestTransferService.addRequestTransfer(dataRequest, authorizationHeader()).then(response => {
      // dispatch to dapp requestTransferByAdmin action
      const requestTransfer = response.data.result.data
      dispatch('dapp/requestTransferByAdmin', requestTransfer, { root: true })

      return response
    })
  },

  approveRequestTransfer({ commit }, data) {
    const dataRequest = {
      id: parseInt(data.requestData.id),
      approveNote: data.approveNote
    }
    return RequestTransferService.approveRequestTransfer(dataRequest, authorizationHeader()).then(response => {
      commit('APPROVE_REQUEST_TRANSFER')

      return response
    })
  },

  rejectRequestTransfer({ commit }, data) {
    return RequestTransferService.rejectRequestTransfer(data, authorizationHeader()).then(response => {
      commit('REJECT_REQUEST_TRANSFER')
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.REJECT_REQUEST_TRANSFER_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  getCrowdfundingAdmins({ commit }) {
    return RequestTransferService.getCrowdfundingAdmins(authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_ADMINS', response.data.result)

      return response
    })
  },

  addCrowdfundingAdmin({ commit }, data) {
    return RequestTransferService.addCrowdfundingAdmin(data, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.ADD_CROWDFUNDING_ADMIN_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  getCrowdfundingAdmin({ commit }, id) {
    return RequestTransferService.getCrowdfundingAdmin(id, authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_ADMIN', response.data.result.data)

      return response
    })
  },

  updateCrowdfundingAdmin({ commit }, data) {
    return RequestTransferService.updateCrowdfundingAdmin(data.id, data, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.UPDATE_CROWDFUNDING_ADMIN_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  }
}

export default actions
