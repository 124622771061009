<template>
  <div id="app">
    <main-layout v-if="$lStorage.has('accessToken')">
      <router-view />
    </main-layout>

    <router-view v-else />
  </div>
</template>

<script>
import MainLayout from './layouts/MainLayout'

export default {
  components: {
    MainLayout
  }
}
</script>
