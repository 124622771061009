import dataSets from '../../support/data-sets'

const state = {
  paymentOrders: null,
  paymentOrdersExport: null,
  paymentOrder: null,
  assets: dataSets.initPaymentOrderAssets,
  paymentTransactions: null,
  reportPaymentOrder: null,
  picBankTransferUrlBlob: ''
}

export default state
