import apiCaller from './api-caller'

const RESOURCES = {
  program: '/program'
}

export default {
  getProgramList(headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.program}/list`, headers })
  },

  updateProgram(id, data, headers) {
    return apiCaller({
      method: 'PUT',
      url: `${RESOURCES.program}/update/${id}`,
      data,
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
