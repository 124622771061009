import {
  STORE_APP_VERSIONS,
  UPDATE_APP_VERSION,
  STORE_REFERRAL_SETTING,
  UPDATE_REFERRAL_SETTING,
  STORE_ADMIN_OF_REFERRAL_SETTING,
  STORE_CONVERT_CURRENCY
} from '../mutation-types'

const mutations = {
  [STORE_APP_VERSIONS](state, payloads) {
    state.platform.ios = payloads.filter(payload => payload.platform === 1)[0]
    state.platform.android = payloads.filter(payload => payload.platform === 2)[0]
  },

  [UPDATE_APP_VERSION](state, payload) {
    payload.platform === 1 ? (state.platform.ios = payload) : (state.platform.android = payload)
  },

  [STORE_REFERRAL_SETTING](state, payload) {
    state.referralReward = payload[0]
    state.kycReward = payload[1]
  },

  [UPDATE_REFERRAL_SETTING](state, payload) {
    state.referralReward.id === payload.id ? (state.referralReward = payload) : (state.kycReward = payload)
  },

  [STORE_ADMIN_OF_REFERRAL_SETTING](state, payload) {
    state.adminReferral = payload
  },

  [STORE_CONVERT_CURRENCY](state, payload) {
    state.convertCurrency = payload
  }
}

export default mutations
