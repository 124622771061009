import GoldInvestmentService from '@/services/gold-investment'
import { authorizationHeader } from '@/store/support/authorization'

const actions = {
  // ======================= gold investment setting ====================== //
  getGoldInvestmentSettings({ commit }) {
    return GoldInvestmentService.getGoldInvestmentSettings(authorizationHeader()).then(response => {
      if (response.data.Result && response.data.Result.settings)
        commit('STORE_GOLD_INVESTMENT_SETTINGS', response.data.Result.settings)

      return response
    })
  },

  updateGoldInvestmentSetting({ commit }, payload) {
    const { id, data } = payload

    return GoldInvestmentService.updateGoldInvestmentSetting(id, data, authorizationHeader()).then(response => {
      commit('UPDATE_GOLD_INVESTMENT_SETTING', response.data.Result)

      return response
    })
  },

  // ======================= gold investment requirement ====================== //

  getGoldInvestmentRequirements({ commit }, params) {
    return GoldInvestmentService.getGoldInvestmentRequirements(params, authorizationHeader()).then(response => {
      if (response.data.Result && response.data.Result.requirements)
        commit('STORE_GOLD_INVESTMENT_REQUIREMENTS', response.data.Result.requirements)

      return response
    })
  },

  addGoldInvestmentRequirement({ commit }, data) {
    return GoldInvestmentService.addGoldInvestmentRequirement(data, authorizationHeader()).then(response => {
      commit('ADD_GOLD_INVESTMENT_REQUIREMENT', response.data.Result)

      return response
    })
  },

  updateGoldInvestmentRequirement({ commit }, payload) {
    const { id, minimum_toko_deposited, minimum_buying_usdt, maximum_buying_usdt } = payload

    return GoldInvestmentService.updateGoldInvestmentRequirement(
      id,
      { minimum_toko_deposited, minimum_buying_usdt, maximum_buying_usdt },
      authorizationHeader()
    ).then(response => {
      commit('UPDATE_GOLD_INVESTMENT_REQUIREMENT', response.data.Result)

      return response
    })
  },

  deleteGoldInvestmentRequirement({ commit }, id) {
    return GoldInvestmentService.deleteGoldInvestmentRequirement(id, authorizationHeader()).then(response => {
      commit('DELETE_GOLD_INVESTMENT_REQUIREMENT', id)
      commit('alert/STORE_ALERT', { type: 'success', content: 'Success', timeOut: 2400 }, { root: true })

      return response
    })
  },

  // ======================= gold investment markup-fees ====================== //

  getGoldInvestmentMarkupFees({ commit }, params) {
    return GoldInvestmentService.getGoldInvestmentMarkupFees(params, authorizationHeader()).then(response => {
      if (response.data.Result && response.data.Result.markup_fees)
        commit('STORE_GOLD_INVESTMENT_MARKUP_FEES', response.data.Result.markup_fees)

      return response
    })
  },

  addGoldInvestmentMarkupFee({ commit }, data) {
    return GoldInvestmentService.addGoldInvestmentMarkupFee(data, authorizationHeader()).then(response => {
      commit('ADD_GOLD_INVESTMENT_MARKUP_FEE', response.data.Result)

      return response
    })
  },

  updateGoldInvestmentMarkupFee({ commit }, payload) {
    const { id, gold_weight, fee } = payload

    return GoldInvestmentService.updateGoldInvestmentMarkupFee(id, { gold_weight, fee }, authorizationHeader()).then(
      response => {
        commit('UPDATE_GOLD_INVESTMENT_MARKUP_FEE', response.data.Result)

        return response
      }
    )
  },

  deleteGoldInvestmentMarkupFee({ commit }, id) {
    return GoldInvestmentService.deleteGoldInvestmentMarkupFee(id, authorizationHeader()).then(response => {
      commit('DELETE_GOLD_INVESTMENT_MARKUP_FEE', id)
      commit('alert/STORE_ALERT', { type: 'success', content: 'Success', timeOut: 2400 }, { root: true })

      return response
    })
  },

  // ======================= gold investment orders ====================== //

  getGoldInvestmentOrders({ commit }, params) {
    return GoldInvestmentService.getGoldInvestmentOrders(params, authorizationHeader()).then(response => {
      commit('STORE_GOLD_INVESTMENT_ORDERS', response.data.Result)

      return response
    })
  }
}

export default actions
