import dataSets from '../../support/data-sets'
import { PAYMENT_ORDER_STATUS } from '@/utils/key-mapping'

import {
  STORE_PAYMENT_ORDERS,
  STORE_PAYMENT_ORDER,
  CLEAR_PAYMENT_ORDER,
  STORE_PAYMENT_TRANSFER_AMOUNT_IMAGE,
  APPROVE_PAYMENT_ORDER,
  REJECT_PAYMENT_ORDER,
  REFUND_PAYMENT_ORDER,
  STORE_PAYMENT_ORDERS_EXPORT_DATA
} from '../mutation-types'

const mutations = {
  [STORE_PAYMENT_ORDERS](state, payload) {
    state.paymentOrders = payload
  },

  [STORE_PAYMENT_ORDER](state, payload) {
    state.paymentOrder = payload
  },

  [CLEAR_PAYMENT_ORDER](state) {
    state.paymentOrder = null
    state.picBankTransferUrlBlob = ''
  },

  [STORE_PAYMENT_TRANSFER_AMOUNT_IMAGE](state, payload) {
    state.picBankTransferUrlBlob = payload
  },

  [APPROVE_PAYMENT_ORDER](state, payload) {
    state.paymentOrder.status = PAYMENT_ORDER_STATUS.approve
    state.paymentOrder.adminNote = payload
  },

  [REJECT_PAYMENT_ORDER](state, payload) {
    state.paymentOrder.status = PAYMENT_ORDER_STATUS.reject
    state.paymentOrder.adminNote = payload
  },

  [REFUND_PAYMENT_ORDER](state, payload) {
    state.paymentOrder.status = PAYMENT_ORDER_STATUS.refund
    state.paymentOrder.adminNote = payload
  },

  [STORE_PAYMENT_ORDERS_EXPORT_DATA](state, payload) {
    state.paymentOrdersExport = payload
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initPaymentOrderAssets
  }
}

export default mutations
