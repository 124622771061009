import apiCaller from './api-caller'

const RESOURCES = {
  goldInvestment: '/gold-investment'
}

export default {
  // ======================= gold investment setting ========================== //

  getGoldInvestmentSettings(headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/settings`, headers })
  },

  updateGoldInvestmentSetting(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.goldInvestment}/setting/${id}`, data, headers })
  },

  // ======================= gold investment requirement ========================== //

  getGoldInvestmentRequirements(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/requirements`, params, headers })
  },

  addGoldInvestmentRequirement(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.goldInvestment}/requirements`, data, headers })
  },

  updateGoldInvestmentRequirement(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.goldInvestment}/requirements/${id}`, data, headers })
  },

  deleteGoldInvestmentRequirement(id, headers) {
    return apiCaller({ method: 'DELETE', url: `${RESOURCES.goldInvestment}/requirements/${id}`, headers })
  },

  // ======================= gold investment markup-fees ========================== //

  getGoldInvestmentMarkupFees(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/markup-fees`, params, headers })
  },

  addGoldInvestmentMarkupFee(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.goldInvestment}/markup-fees`, data, headers })
  },

  updateGoldInvestmentMarkupFee(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.goldInvestment}/markup-fees/${id}`, data, headers })
  },

  deleteGoldInvestmentMarkupFee(id, headers) {
    return apiCaller({ method: 'DELETE', url: `${RESOURCES.goldInvestment}/markup-fees/${id}`, headers })
  },

  // ======================= gold investment orders ========================== //

  getGoldInvestmentOrders(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/orders`, params, headers })
  },

  getGoldInvestmentOrder(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/orders/${id}`, headers })
  },

  // ======================= gold investment withdraws ========================== //

  getGoldInvestmentWithdraws(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/withdraws`, params, headers })
  },

  getGoldInvestmentWithdraw(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.goldInvestment}/withdraws/${id}`, headers })
  },

  approveGoldInvestmentWithdraw(id, data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.goldInvestment}/withdraws/${id}`, data, headers })
  },

  rejectGoldInvestmentWithdraw(id, data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.goldInvestment}/withdraws/${id}`, data, headers })
  }
}
