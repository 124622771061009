import { STORE_ALERT, CLEAR_ALERT } from '../mutation-types'

const state = {
  alert: null
}

const mutations = {
  [STORE_ALERT](state, payload) {
    state.alert = payload
  },

  [CLEAR_ALERT](state) {
    state.alert = null
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
