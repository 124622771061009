<template>
  <header class="header-wrapper">
    <div class="container">
      <div class="header">
        <h2 class="headline--lg text-white text-bold">Welcome back, {{ currentUser && currentUser.fullName }}!</h2>

        <router-link class="tile tile--sm link--clone" tag="div" :to="{ name: 'Profile' }">
          <div class="tile__image">
            <toko-profile-purple-icon />
          </div>
          <div class="tile__content">
            <p class="text-bold text-white headline">{{ currentUser && currentUser.fullName }}</p>
            <p class="text-lightest-purple">{{ sysUserRole }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import TokoProfilePurpleIcon from '@/ui/toko-icons/TokoProfilePurple'

export default {
  name: 'AppHeader',

  components: {
    TokoProfilePurpleIcon
  }
}
</script>
