import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import auth from './modules/auth'
import report from './modules/report'
import staking from './modules/staking'
import setting from './modules/setting'
import user from './modules/user'
import transaction from './modules/transaction'
import referral from './modules/referral'
import program from './modules/program'
import alert from './modules/alert'
import crowdfunding from './modules/crowdfunding'
import dapp from './modules/dapp'
import boUsers from './modules/bo-user'
import requestTransfer from './modules/request-transfer'
import smartContract from './modules/smart-contract'
import swapToko from './modules/swap-toko'
import swapTokoStaking from './modules/swap-toko-staking'
import payment from './modules/payment'
import goldInvestment from './modules/gold-investment'
import convertToko from './modules/convert-toko'
import requestLog from './modules/request-log'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    report,
    staking,
    setting,
    user,
    transaction,
    referral,
    program,
    alert,
    crowdfunding,
    dapp,
    boUsers,
    requestTransfer,
    smartContract,
    swapToko,
    swapTokoStaking,
    payment,
    goldInvestment,
    convertToko,
    requestLog
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
