const set = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

const get = key => {
  const data = localStorage.getItem(key)
  return JSON.parse(data)
}

const remove = key => {
  localStorage.removeItem(key)
}

const has = key => {
  return !!localStorage.getItem(key)
}

export default {
  set,
  get,
  remove,
  has
}
