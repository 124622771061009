import SmartContractService from '@/services/smart-contract'
import { authorizationHeader } from '@/store/support/authorization'
import { SMART_CONTRACT_TYPE } from '@/utils/key-mapping'
import {
  STORE_CF_SMART_CONTRACT,
  STORE_USDT_SMART_CONTRACT,
  STORE_STAKE_V2_SMART_CONTRACT,
  STORE_STAKE_SMART_CONTRACT
} from '../mutation-types'

const state = {
  stakeSmartContract: null,
  cfSmartContract: null,
  usdtSmartContract: null,
  stakeV2SmartContract: null
}

const mutations = {
  [STORE_CF_SMART_CONTRACT](state, payload) {
    const index = payload.findIndex(contract => contract.type === SMART_CONTRACT_TYPE.crowdfunding)
    if (index < 0) return

    state.cfSmartContract = payload[index]
  },

  [STORE_USDT_SMART_CONTRACT](state, payload) {
    const index = payload.findIndex(contract => contract.type === SMART_CONTRACT_TYPE.usdt)
    if (index < 0) return

    state.usdtSmartContract = payload[index]
  },

  [STORE_STAKE_V2_SMART_CONTRACT](state, payload) {
    const index = payload.findIndex(contract => contract.type === SMART_CONTRACT_TYPE.stakeV2)
    if (index < 0) return

    state.stakeV2SmartContract = payload[index]
  },

  [STORE_STAKE_SMART_CONTRACT](state, payload) {
    const index = payload.findIndex(contract => contract.type === SMART_CONTRACT_TYPE.stake)
    if (index < 0) return

    state.stakeSmartContract = payload[index]
  }
}

const actions = {
  getSmartContracts({ commit }) {
    return SmartContractService.getSmartContracts(authorizationHeader()).then(response => {
      commit('STORE_CF_SMART_CONTRACT', response.data.result.data)
      commit('STORE_USDT_SMART_CONTRACT', response.data.result.data)
      commit('STORE_STAKE_V2_SMART_CONTRACT', response.data.result.data)
      commit('STORE_STAKE_SMART_CONTRACT', response.data.result.data)

      return response
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
