import moment from '@/moment'

const getters = {
  paymentOrdersSelect: state => {
    return state.paymentOrders.data.filter(order => order.user !== null)
  },
  paymentOrdersExportData: state => {
    if (!state.paymentOrdersExport) return

    const orders = state.paymentOrdersExport
    return orders.map(order => {
      return {
        'Order ID': order.uid,
        'User Name': order?.user.full_name,
        Email: order?.user.email,
        'Phone Number': `(${order?.user.country_code})${order?.user.phone}`,
        'Amount Currency': order.amount_currency,
        Fee: order.fee,
        'Total Amount': order.amount_currency + order.fee,
        'Created Date': moment(order.created_at_milliseconds).format('LTS')
      }
    })
  }
}

export default getters
