import 'formdata-polyfill'
import CrowdfundingService from '@/services/crowdfunding'
import { authorizationHeader } from '@/store/support/authorization'

const actions = {
  getCrowdfundingPlans({ commit, state }) {
    const params = {
      limit: 9999,
      page: 1
    }

    return CrowdfundingService.getCrowdfundingPlans(state.crowdfundingInfo.id, params, authorizationHeader()).then(
      response => {
        commit('STORE_CROWDFUNDING_PLANS', response.data.result)

        return response
      }
    )
  },

  getCrowdfundingPlan({ commit }, data) {
    const { planId, programId } = data

    return CrowdfundingService.getCrowdfundingPlan(programId, planId, authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_PLAN', response.data.result.data)

      return response
    })
  },

  // eslint-disable-next-line no-unused-vars
  addCrowdfundingPlan({ commit }, data) {
    let formData = new FormData()
    Object.keys(data).forEach(key => formData.set(`${key}`, data[key] || ''))

    return CrowdfundingService.addCrowdfundingPlan(formData, authorizationHeader()).then(response => {
      return response
    })
  },

  // eslint-disable-next-line no-unused-vars
  updateCrowdfundingPlan({ commit }, payload) {
    const { planId, data } = payload

    let formData = new FormData()
    Object.keys(data).forEach(key => formData.set(`${key}`, data[key] || ''))

    return CrowdfundingService.updateCrowdfundingPlan(planId, formData, authorizationHeader()).then(response => {
      return response
    })
  },

  // eslint-disable-next-line no-unused-vars
  deleteCrowdfundingPlan({ commit }, payload) {
    const { programId, planId } = payload

    return CrowdfundingService.deleteCrowdfundingPlan(planId, { program_id: programId }, authorizationHeader()).then(
      response => {
        return response
      }
    )
  },

  getCrowdfundingUsers({ commit }, params) {
    return CrowdfundingService.getCrowdfundingUsers(params, authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_JOINED_USERS', response.data.result)

      return response
    })
  },

  getCrowdfundingUser({ commit }, params) {
    return CrowdfundingService.getCrowdfundingUsers(params, authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_JOINED_USER', response.data.result.data[0])

      return response
    })
  },

  getCrowdfundingFeeds({ commit }, params) {
    return CrowdfundingService.getCrowdfundingFeeds(params, authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_FEEDS', response.data.result)

      return response
    })
  },

  getCrowdfundingFeed({ commit }, id) {
    return CrowdfundingService.getCrowdfundingFeed(id, authorizationHeader()).then(response => {
      commit('STORE_CROWDFUNDING_FEED', response.data.result.data)

      return response
    })
  }
}

export default actions
