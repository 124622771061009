import dataSets from '../../support/data-sets'
import { STORE_REQUEST_LOGS, STORE_REQUEST_LOG, CLEAR_REQUEST_LOG } from '../mutation-types'

const mutations = {
  [STORE_REQUEST_LOGS](state, payload) {
    state.requestLogs = payload
  },

  [STORE_REQUEST_LOG](state, payload) {
    state.requestLog = payload
  },

  [CLEAR_REQUEST_LOG](state) {
    state.requestLog = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initRequestLogAssets
  }
}

export default mutations
