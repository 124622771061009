import Vue from 'vue'
import Vuelidate from 'vuelidate'
import JsonCSV from 'vue-json-csv'
import VueSanitize from 'vue-sanitize'
import VueCountdownTimer from 'vuejs-countdown-timer'

import App from './App.vue'
import router from './router'
import store from './store'
import moment from './moment'
import formatDateTime from './utils/date-time'
import formatGroupByOptions from './utils/group-by-options'
import lStorage from './local-storage'
import planValidate from './utils/plan-validation'
import currentUser from './mixins/current-user'
import accessRule from './authorization/access-rules'
import authorization from './authorization'

import '@/filters'
import './scss/index.scss'

// use download csv as a component
Vue.component('downloadCsv', JsonCSV)

Vue.use(Vuelidate)
Vue.use(VueSanitize, {})
Vue.use(VueCountdownTimer)

Vue.prototype.$moment = moment
Vue.prototype.$formatDateTime = formatDateTime
Vue.prototype.$formatGroupByOptions = formatGroupByOptions
Vue.prototype.$lStorage = lStorage
Vue.prototype.$planValidate = planValidate
Vue.prototype.$accessRule = accessRule
Vue.prototype.$auth = authorization

Vue.mixin(currentUser)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
