/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import VueRouter from 'vue-router'
import lStorage from '@/local-storage'
import store from '@/store'
import Auth from '@/authorization'
import accessRules from '@/authorization/access-rules'
import { TRANSACTION_OPTION_DATA_TYPE } from '@/utils/key-mapping'

Vue.use(VueRouter)

const lazyLoadRoute = pageName => {
  return () => import(/* webpackChunkName: "route" */ `@/pages/${pageName}`)
}

const routes = [
  /*
    dashboard routing
  */
  { path: '/', redirect: '/dashboard', meta: { root: 'Dashboard' } },
  {
    path: '/dashboard/user-register',
    name: 'DashboardUserRegister',
    component: lazyLoadRoute('dashboard/user/Dashboard'),
    meta: {
      root: 'Dashboard'
    }
  },
  // TODO: implement dashboard
  // {
  //   path: '/dashboard/referral',
  //   name: 'DashboardReferral',
  //   component: lazyLoadRoute('dashboard/referral/Dashboard'),
  //   meta: {
  //     root: 'Dashboard'
  //   }
  // },
  // {
  //   path: '/dashboard/crowdfunding',
  //   name: 'DashboardCrowdfunding',
  //   component: lazyLoadRoute('dashboard/crowdfunding/Dashboard'),
  //   meta: {
  //     root: 'Dashboard'
  //   }
  // },
  {
    path: '/dashboard',
    name: 'DashboardStaking',
    component: lazyLoadRoute('dashboard/staking/Dashboard'),
    meta: {
      root: 'Dashboard'
    }
  },

  /*
    programs routing
  */
  {
    path: '/programs/staking',
    name: 'ProgramsStaking',
    component: lazyLoadRoute('program/Staking'),
    meta: {
      root: 'Programs'
    }
  },
  {
    path: '/programs/crowdfunding',
    name: 'CrowdfundingPlans',
    component: lazyLoadRoute('program/Crowdfunding'),
    meta: {
      root: 'Programs'
    }
  },
  {
    path: '/programs/gold-investment',
    name: 'GoldInvestment',
    component: lazyLoadRoute('program/GoldInvestment'),
    meta: {
      root: 'Programs'
    }
  },
  {
    path: '/programs/gold-investment/edit',
    name: 'GoldInvestmentEdit',
    component: lazyLoadRoute('program/GoldInvestmentEdit'),
    meta: {
      root: 'Programs'
    }
  },
  // {
  //   path: '/programs/crowdfunding/:programId/new',
  //   name: 'CrowdfundingPlanNew',
  //   component: lazyLoadRoute('plan/FundNew'),
  //   meta: {
  //     root: 'Programs'
  //   }
  // },
  {
    path: '/programs/crowdfunding/:programId/plans/:planId',
    name: 'CrowdfundingPlan',
    component: lazyLoadRoute('plan/Fund'),
    meta: {
      root: 'Programs'
    }
  },
  // {
  //   path: '/programs/crowdfunding/:programId/plans/:planId/edit',
  //   name: 'CrowdfundingPlanEdit',
  //   component: lazyLoadRoute('plan/FundEdit'),
  //   meta: {
  //     root: 'Programs'
  //   }
  // },
  // {
  //   path: '/programs/:programId/plans/new',
  //   name: 'PlanNew',
  //   component: lazyLoadRoute('plan/PlanNew'),
  //   meta: {
  //     root: 'Programs'
  //   }
  // },
  // {
  //   path: '/programs/:programId/plans/:id/edit',
  //   name: 'PlanEdit',
  //   component: lazyLoadRoute('plan/PlanEdit'),
  //   meta: {
  //     root: 'Programs'
  //   }
  // },
  {
    path: '/programs/:programId/plans/:id',
    name: 'Plan',
    component: lazyLoadRoute('plan/Plan'),
    meta: {
      root: 'Programs'
    }
  },

  /*
    users routing
  */
  {
    path: '/users/crowdfunding/:id',
    name: 'CrowdfundingUser',
    component: lazyLoadRoute('user/crowdfunding/CrowdfundingUser'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/crowdfunding',
    name: 'CrowdfundingUsers',
    component: lazyLoadRoute('user/crowdfunding/CrowdfundingUsers'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/crowdfunding-feed/:id',
    name: 'CrowdfundingFeed',
    component: lazyLoadRoute('user/crowdfunding/CrowdfundingFeed'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/crowdfunding-feed',
    name: 'CrowdfundingFeeds',
    component: lazyLoadRoute('user/crowdfunding/CrowdfundingFeeds'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/referral',
    name: 'UserReferrers',
    component: lazyLoadRoute('user/referral/Referrers'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/referral/:id',
    name: 'UserReferrer',
    component: lazyLoadRoute('user/referral/Referrer'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/staking/:id',
    name: 'StakingUser',
    component: lazyLoadRoute('user/staking/StakingUser'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/staking',
    name: 'StakingUsers',
    component: lazyLoadRoute('user/staking/StakingUsers'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/all',
    name: 'Users',
    component: lazyLoadRoute('user/all/Users'),
    meta: {
      root: 'Users'
    }
  },
  {
    path: '/users/detail/:id',
    name: 'User',
    component: lazyLoadRoute('user/all/User'),
    meta: {
      root: 'Users'
    }
  },
  { path: '/users', redirect: '/users/all' },

  /*
    settings routing
  */
  { path: '/settings', name: 'Settings', component: lazyLoadRoute('setting/Setting') },

  /*
    request-transfer routing
  */
  {
    path: '/withdraw/admin',
    name: 'WithdrawAdmins',
    component: lazyLoadRoute('withdraw/Admins'),
    meta: {
      rule: accessRules.CrowdfundingAdminView
    }
  },
  {
    path: '/withdraw/admin/new',
    name: 'WithdrawAdminNew',
    component: lazyLoadRoute('withdraw/AdminNew'),
    meta: {
      rule: accessRules.CrowdfundingAdminUpdate
    }
  },
  {
    path: '/withdraw/admin/:id',
    name: 'WithdrawAdmin',
    component: lazyLoadRoute('withdraw/Admin'),
    meta: {
      rule: accessRules.CrowdfundingAdminView
    }
  },
  {
    path: '/withdraw/admin/:id/update',
    name: 'WithdrawAdminUpdate',
    component: lazyLoadRoute('withdraw/AdminUpdate'),
    meta: {
      rule: accessRules.CrowdfundingAdminUpdate
    }
  },
  { path: '/withdraw', name: 'Withdraw', component: lazyLoadRoute('withdraw/Withdraw') },
  { path: '/withdraw/new', name: 'WithdrawNew', component: lazyLoadRoute('withdraw/WithdrawNew') },
  { path: '/withdraw/:id', name: 'WithdrawDetail', component: lazyLoadRoute('withdraw/WithdrawDetail') },

  /*
    transactions routing
  */
  {
    path: '/transactions/staking',
    name: 'TransactionsStaking',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.staking
    }
  },
  {
    path: '/transactions/event',
    name: 'TransactionsEvent',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.event
    }
  },
  {
    path: '/transactions/referral',
    name: 'TransactionsReferral',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.referral
    }
  },
  {
    path: '/transactions/transfer-erc20',
    name: 'TransactionsERC20',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.transferERC20
    }
  },
  {
    path: '/transactions/swap-toko',
    name: 'TransactionsSwapToko',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.swapToko
    }
  },
  {
    path: '/transactions/error-actions',
    name: 'TransactionsErrorActions',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions'
    }
  },
  {
    path: '/transactions/crowdfunding-order',
    name: 'TransactionsCrowdfundingOrder',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.crowdfundingOrder
    }
  },
  // TODO: implement all transaction by type
  // {
  //   path: '/transactions/double-toko',
  //   name: 'TransactionsDoubleToko',
  //   component: lazyLoadRoute('transaction/Transactions'),
  //   meta: {
  //     root: 'Transactions',
  //     type: TRANSACTION_OPTION_DATA_TYPE.eventDoubleToko
  //   }
  // },
  // {
  //   path: '/transactions/crowdfunding',
  //   name: 'TransactionsCrowdfunding',
  //   component: lazyLoadRoute('transaction/Transactions'),
  //   meta: {
  //     root: 'Transactions',
  //     type: TRANSACTION_OPTION_DATA_TYPE.crowdfundingOrder
  //   }
  // },
  {
    path: '/transactions/all',
    name: 'Transactions',
    component: lazyLoadRoute('transaction/Transactions'),
    meta: {
      root: 'Transactions',
      type: TRANSACTION_OPTION_DATA_TYPE.all
    }
  },
  { path: '/transactions', redirect: '/transactions/all' },
  {
    path: '/transactions/detail/:id',
    name: 'Transaction',
    component: lazyLoadRoute('transaction/Transaction'),
    meta: {
      root: 'Transactions'
    }
  },
  {
    path: '/etherscan-address/:id',
    name: 'ScanEtherAddress',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.open(`${process.env.VUE_APP_ETHERSCAN_URL}/address/${to.params.id}`, '_blank')
    }
  },
  {
    path: '/etherscan/:id',
    name: 'ScanEtherTransactionHash',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.open(`${process.env.VUE_APP_ETHERSCAN_URL}/tx/${to.params.id}`, '_blank')
    }
  },
  {
    path: '/bsc-address/:id',
    name: 'ScanBSCAddress',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.open(`${process.env.VUE_APP_BSC_SCAN_URL}/address/${to.params.id}`, '_blank')
    }
  },
  {
    path: '/bsc/:id',
    name: 'ScanBSCTransactionHash',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.open(`${process.env.VUE_APP_BSC_SCAN_URL}/tx/${to.params.id}`, '_blank')
    }
  },
  {
    path: '/blockcypher/:id',
    name: 'ScanBTCTransactionHash',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
      window.open(`${process.env.VUE_APP_BLOCKCYPHER_URL}/${to.params.id}`, '_blank')
    }
  },

  /*
    balance routing
  */
  { path: '/balance', name: 'Balance', component: lazyLoadRoute('balance/Balance') },

  /*
    admin (bo-user) routing
  */
  { path: '/admin/list', redirect: '/admin' },
  {
    path: '/admin/register',
    name: 'AdminRegister',
    component: lazyLoadRoute('admin/Register'),
    meta: {
      root: 'Admin',
      rule: accessRules.UserUpdate
    }
  },
  {
    path: '/admin',
    name: 'ListAdmin',
    component: lazyLoadRoute('admin/ListAdmin'),
    meta: {
      root: 'Admin',
      rule: accessRules.UserView
    }
  },

  /*
    auth routing
  */
  { path: '/profile', name: 'Profile', component: lazyLoadRoute('auth/Profile') },
  { path: '/login', name: 'Login', component: lazyLoadRoute('auth/Login') },

  /*
    swap-toko routing
  */
  {
    path: '/swap-toko/claim',
    name: 'SwapClaimBonus',
    component: lazyLoadRoute('swap/Claims'),
    meta: {
      root: 'SwapToko',
      rule: accessRules.SwapTokoView
    }
  },
  {
    path: '/swap-toko/claim/:id',
    name: 'SwapClaimBonusDetail',
    component: lazyLoadRoute('swap/Claim'),
    meta: {
      root: 'SwapToko',
      rule: accessRules.SwapTokoView
    }
  },
  {
    path: '/swap-toko/tnxs',
    name: 'SwapUserTransfers',
    component: lazyLoadRoute('swap/UserTransfers'),
    meta: {
      root: 'SwapToko',
      rule: accessRules.SwapTokoView
    }
  },
  {
    path: '/swap-toko/tnxs/:id',
    name: 'SwapUserTransfer',
    component: lazyLoadRoute('swap/UserTransfer'),
    meta: {
      root: 'SwapToko',
      rule: accessRules.SwapTokoView
    }
  },

  /*
    payment
  */
  {
    path: '/payment/order',
    name: 'PaymentOrders',
    component: lazyLoadRoute('payment/PaymentOrders'),
    meta: {
      root: 'Transactions'
      // rule: accessRules.PaymentOrderView
    }
  },
  {
    path: '/payment/order/:id',
    name: 'PaymentOrder',
    component: lazyLoadRoute('payment/PaymentOrder'),
    meta: {
      root: 'Transactions'
      // rule: accessRules.PaymentOrderView
    }
  },

  /*
    gold
  */
  {
    path: '/gold-investment',
    name: 'GoldInvestmentTnxs',
    component: lazyLoadRoute('gold-investment/GoldInvestments'),
    meta: {
      root: 'Transactions'
    }
  },
  {
    path: '/gold-investment/order/:id',
    name: 'GoldInvestmentOrder',
    component: lazyLoadRoute('gold-investment/GoldInvestmentOrder'),
    meta: {
      root: 'Transactions'
    }
  },
  {
    path: '/gold-investment/withdraw/:id',
    name: 'GoldInvestmentWithdraw',
    component: lazyLoadRoute('gold-investment/GoldInvestmentWithdraw'),
    meta: {
      root: 'Transactions'
    }
  },

  /*
    Convert Toko
  */
  {
    path: '/convert-toko',
    name: 'ConvertTokoOrders',
    component: lazyLoadRoute('convert-toko/ConvertTokoOrders'),
    meta: {
      root: 'ConvertToko'
    }
  },

  {
    path: '/convert-toko/:id',
    name: 'ConvertTokoOrder',
    component: lazyLoadRoute('convert-toko/ConvertTokoOrder'),
    meta: {
      root: 'ConvertToko'
    }
  },

  /*
    Monitoring
  */
  {
    path: '/monitoring',
    name: 'Monitoring',
    component: lazyLoadRoute('monitor/Monitor'),
    meta: {
      root: 'Monitor'
    }
  },

  /*
   Audit logs
  */
  {
    path: '/audit-logs',
    name: 'AuditLogs',
    component: lazyLoadRoute('audit'),
    meta: {
      root: ''
    }
  },

  /*
    system routing
  */
  { path: '*', name: 'NotFound', component: lazyLoadRoute('system/NotFound') }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

// pager guard
const ROUTING_FREE = ['Login', 'ForgotPassword']

router.beforeEach(async (to, from, next) => {
  const isRouteFree = ROUTING_FREE.indexOf(to.name) !== -1
  if (lStorage.has('accessToken') && !store.state.auth.currentUser) await store.dispatch('auth/getUserProfile')
  const { currentUser } = store.state.auth
  const accessable = new Auth('Page', currentUser, to.meta.rule).canView()

  if (currentUser) {
    if (isRouteFree) {
      next('/')
    } else if (to.meta.rule) {
      if (accessable) {
        next()
      } else {
        next({ name: 'NotFound' })
      }
    } else {
      next()
    }
  } else {
    if (isRouteFree) {
      next()
    } else {
      next('/login')
    }
  }
})

// prototypes
VueRouter.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject)
  window.open(href, '_blank')
}

export default router
