import AuthService from '@/services/auth'
import router from '@/router'
import lStorage from '@/local-storage'
import { authorizationHeader } from '@/store/support/authorization'
import * as message from '@/store/support/message'

const actions = {
  getUserProfile({ commit }) {
    return AuthService.getUserProfile(authorizationHeader()).then(({ data }) => {
      commit('STORE_PROFILE', data.result.data)
    })
  },

  updateUserProfile({ dispatch }, params) {
    return AuthService.updateUserProfile(params, authorizationHeader()).then(response => {
      dispatch('getUserProfile')

      return response
    })
  },

  login({ commit }, payload) {
    return AuthService.login(payload)
      .then(({ data }) => {
        if (data.result) {
          lStorage.set('accessToken', data.result.data)
          commit('STORE_ACCESS_TOKEN', data.result.data.token)

          // redirect to dashboard
          router.push('/')
        }
      })
      .catch(error => {
        commit('alert/STORE_ALERT', { type: 'error', content: message.DATA_INCORRECT, timeOut: 1800 }, { root: true })
        throw error
      })
  },

  changePassword({ commit, dispatch }, data) {
    return AuthService.changePassword(data, authorizationHeader())
      .then(() => {
        dispatch('logout', () => {
          commit(
            'alert/STORE_ALERT',
            { type: 'success', content: message.CHANGE_PASSWORD_SUCCESS, timeOut: 1800 },
            { root: true }
          )
        })
      })
      .catch(error => {
        if (error.response && error.response.status !== 401)
          commit('alert/STORE_ALERT', { type: 'error', content: message.DATA_INCORRECT, timeOut: 1600 }, { root: true })
        throw error
      })
  },

  logout({ commit }, callback) {
    commit('CLEAR_AUTH_DATA')
    lStorage.remove('accessToken')
    router.push('/login')

    if (callback) {
      setTimeout(() => {
        callback()
      }, 0)
    }
  }
}

export default actions
