import apiCaller from './api-caller'

const RESOURCES = {
  reportContract: '/report/contract',
  reportStaking: '/report/staking',
  reportRegisterChart: '/report/wallet/user/chart',
  reportPayment: '/report/payment'
}

export default {
  getReportBalanceSummary(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportContract}/balance/summary`, params, headers })
  },

  getReportBalanceChart(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportStaking}/balance/chart`, params, headers })
  },

  getReportJoinedUsersChart(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportStaking}/users/chart`, params, headers })
  },

  getReportRegisterChart(params, headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.reportRegisterChart, params, headers })
  },

  getReportPaymentSummary(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportPayment}/summary`, params, headers })
  }
}
