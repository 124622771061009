import lStorage from '@/local-storage'

export const plansOrdered = ({ lStoreKey, plans }) => {
  const planIdsOrdered = lStorage.get(lStoreKey)

  if (planIdsOrdered) {
    // eslint-disable-next-line no-unused-vars
    const plansOrdered = plans.reduce(
      (acc, currentValue, currentIndex) => {
        const index = planIdsOrdered.indexOf(currentValue.id)
        index !== -1 ? (acc.plans[index] = currentValue) : acc.plansExpand.push(currentValue)

        return acc
      },
      { plans: [], plansExpand: [] }
    )

    return [...plansOrdered.plansExpand, ...plansOrdered.plans]
  }

  return plans
}
