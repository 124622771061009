<template>
  <li :class="['accordion accordion--navigation', { 'is-active': active, 'navigation-active': isNavigationActive }]">
    <div class="accordion__header" @click="active = !active">
      <slot name="header" />

      <span class="accordion__collapse"></span>
    </div>
    <ul class="accordion__body">
      <router-link
        v-for="(nav, index) in navigations"
        :key="index"
        :to="{ name: nav.name }"
        :exact="nav.exact"
        tag="li"
        class="accordion__link"
      >
        {{ nav.text }}
      </router-link>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'GroupNavigation',

  data() {
    return {
      active: false,
      isNavigationActive: false
    }
  },

  props: {
    name: {
      type: String,
      require: true,
      validator: function(value) {
        return ['Dashboard', 'Users', 'Admin', 'Programs', 'Transactions', 'SwapToko'].indexOf(value) !== -1
      }
    },

    navigations: {
      type: Array,
      require: true
    }
  },

  methods: {
    handleActiveRootPath(meta) {
      this.isNavigationActive = false

      const root = meta && meta.root
      if (!root) return

      // toggle active root navigation
      this.isNavigationActive = root.toUpperCase().includes(this.name.toUpperCase())
      // auto open group navigation when created
      if (root.toUpperCase().includes(this.name.toUpperCase())) this.active = true
    }
  },

  created() {
    this.handleActiveRootPath(this.$router.currentRoute.meta)
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.handleActiveRootPath(to.meta)
    }
  }
}
</script>
