/* eslint-disable space-before-function-paren */
import axios from 'axios'
import { decamelizeKeys, camelizeKeys } from 'humps'
import { deepCopy } from '@/utils/json-parser'
import router from '@/router'
import store from '@/store'
import * as messages from '@/store/support/message'
import lStorage from '@/local-storage'

axios.defaults.baseURL = process.env.VUE_APP_REQUEST_URL
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.request.use(
  function (config) {
    if (config.data) {
      config.data = deepCopy(decamelizeKeys(config.data))
    }

    if (config.params) {
      config.params = deepCopy(decamelizeKeys(config.params))
    }

    return config
  },

  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    if (!response.headers['content-type'].includes('image') && response.data) {
      response.data = deepCopy(camelizeKeys(response.data))
    }

    return response
  },

  function (error) {
    if (error.response && error.response.status === 401) {
      lStorage.remove('accessToken')
      store.commit('auth/CLEAR_AUTH_DATA')
      store.commit('alert/STORE_ALERT', { type: 'error', content: messages.EXPIRED_TOKEN, timeOut: 1600 })
      if (router.currentRoute.path !== '/login') router.push('/login')
    } else if (error.response && error.response.status === 400) {
      store.commit('alert/STORE_ALERT', { type: 'error', content: messages.DATA_INCORRECT, timeOut: 1600 })
    } else if (error.response && error.response.status === 403) {
      store.commit('alert/STORE_ALERT', { type: 'error', content: messages.PERMISSION_DENIED, timeOut: 1600 })
    } else {
      store.commit('alert/STORE_ALERT', { type: 'error', content: messages.INTERNAL_SERVER_ERROR, timeOut: 1600 })
    }

    return Promise.reject(error)
  }
)

export default function apiCaller({
  method = 'GET',
  url,
  params = null,
  data = null,
  headers = null,
  responseType = 'json'
}) {
  return axios({
    method,
    url,
    params,
    data,
    headers,
    responseType
  })
}
