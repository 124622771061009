import BoUserService from '@/services/bo-user'
import { authorizationHeader } from '@/store/support/authorization'
import * as message from '@/store/support/message'

const actions = {
  getBoUsers({ commit }) {
    return BoUserService.getBoUsers(authorizationHeader()).then(({ data }) => {
      commit('STORE_BO_USERS', data.result.data)
    })
  },

  forceUpdateBoUser({ commit }, data) {
    return BoUserService.forceUpdateBoUser(data.id, data.dataRequest, authorizationHeader()).then(response => {
      commit('UPDATE_BO_USER', response.data.result.data)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.UPDATE_USER_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  deleteBoUser({ commit }, id) {
    return BoUserService.deleteBoUser(id, authorizationHeader()).then(response => {
      commit('DELETE_BO_USER', id)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.DELETE_USER_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  register({ commit }, data) {
    return BoUserService.register(data, authorizationHeader())
      .then(response => {
        commit(
          'alert/STORE_ALERT',
          { type: 'success', content: message.CREATE_USER_SUCCESS, timeOut: 1800 },
          { root: true }
        )

        return response
      })
      .catch(error => {
        if (error.response && error.response.status !== 401)
          commit('alert/STORE_ALERT', { type: 'error', content: message.EMAIL_EXISTS, timeOut: 1800 }, { root: true })
        throw error
      })
  }
}

export default actions
