import apiCaller from './api-caller'

const RESOURCES = {
  reportReferral: '/report/referral'
}

export default {
  getReportReferrers(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportReferral}/referrer`, params, headers })
  },

  getReportReferrer(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportReferral}/referrer/${id}`, headers })
  },

  getReportReferralRecipient(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.reportReferral}/recipient`, params, headers })
  }
}
