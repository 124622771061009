import dataSets from '../../support/data-sets'
import {
  STORE_SWAP_TOKO_STAKINGS,
  CLEAR_SWAP_TOKO_STAKINGS_DATA,
  STORE_SWAP_TOKO_STAKING,
  CLEAR_SWAP_TOKO_STAKING_DATA,
  APPROVE_SWAP_TOKO_STAKING,
  REJECT_SWAP_TOKO_STAKING
} from '../mutation-types'

const mutations = {
  [STORE_SWAP_TOKO_STAKINGS](state, payload) {
    state.swapTokoStakings = payload
  },

  [CLEAR_SWAP_TOKO_STAKINGS_DATA](state) {
    state.swapTokoStakings = null
  },

  [STORE_SWAP_TOKO_STAKING](state, payload) {
    state.swapTokoStaking = payload
  },

  [CLEAR_SWAP_TOKO_STAKING_DATA](state) {
    state.swapTokoStaking = null
  },

  [APPROVE_SWAP_TOKO_STAKING](state, payload) {
    state.swapTokoStaking = payload
  },

  [REJECT_SWAP_TOKO_STAKING](state, payload) {
    state.swapTokoStaking = payload
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initSwapTokoStakingAssets
  }
}

export default mutations
