import dataSets from '../../support/data-sets'

const state = {
  requestTransfers: null,
  requestTransfer: null,
  assets: dataSets.initWithdrawAssets,
  crowdfundingAdmins: null,
  crowdfundingAdmin: null
}

export default state
