import dataSets from '../../support/data-sets'

import {
  STORE_CROWDFUNDING_INFO,
  UPDATE_CROWDFUNDING_INFO,
  STORE_CROWDFUNDING_PLANS,
  STORE_CROWDFUNDING_PLAN,
  CLEAR_CROWDFUNDING_PLAN,
  STORE_CROWDFUNDING_JOINED_USERS,
  STORE_CROWDFUNDING_JOINED_USER,
  CLEAR_CROWDFUNDING_JOINED_USER,
  STORE_CROWDFUNDING_FEEDS,
  STORE_CROWDFUNDING_FEED,
  CLEAR_CROWDFUNDING_FEED
} from '../mutation-types'

const mutations = {
  [STORE_CROWDFUNDING_INFO](state, payload) {
    state.crowdfundingInfo = payload
  },

  [UPDATE_CROWDFUNDING_INFO](state, payload) {
    state.crowdfundingInfo = payload
  },

  [STORE_CROWDFUNDING_PLANS](state, payload) {
    state.plans = payload.data
  },

  [STORE_CROWDFUNDING_PLAN](state, payload) {
    state.plan = payload
  },

  [CLEAR_CROWDFUNDING_PLAN](state) {
    state.plan = null
  },

  [STORE_CROWDFUNDING_JOINED_USERS](state, payload) {
    state.joinedUsers = payload
  },

  [STORE_CROWDFUNDING_JOINED_USER](state, payload) {
    state.joinedUser = payload
  },

  [CLEAR_CROWDFUNDING_JOINED_USER](state) {
    state.joinedUser = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initCrowdfundingUsersAssets
  },

  [STORE_CROWDFUNDING_FEEDS](state, payload) {
    state.feeds = payload
  },

  [STORE_CROWDFUNDING_FEED](state, payload) {
    state.feed = payload
  },

  [CLEAR_CROWDFUNDING_FEED](state) {
    state.feed = null
  },

  ['STORE_FEED_ASSETS'](state, payload) {
    state.feedAssets = payload
  },

  ['CLEAR_FEED_ASSETS'](state) {
    state.feedAssets = dataSets.initCrowdfundingFeedsAssets
  }
}

export default mutations
