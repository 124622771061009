// error message
export const DATA_INCORRECT = 'Your data is incorrect'
export const DATA_EXISTS = 'Your data already exists.'
export const EMAIL_EXISTS = 'Email already exists.'
export const EXPIRED_TOKEN = 'Your session has been expired!'
export const INTERNAL_SERVER_ERROR = 'An error occurred. Data may be missing, please contact admin!'
export const PERMISSION_DENIED = 'You dont have permission!'
export const SOMETHING_WRONG = 'Something wrong, please check again!'

// end user message
export const APPROVE_USER_SUCCESS = 'Approve user successfully'
export const REJECT_USER_SUCCESS = 'Reject user successfully'
export const UPDATE_DOCUMENT_SUCCESS = 'Update user document code successfully'

// setting message
export const UPDATE_APP_VERSION_SUCCESS = 'App version is updated'
export const UPDATE_REWARD_AMOUNT_SUCCESS = 'Reward amount is updated'

// auth message
export const CREATE_USER_SUCCESS = 'Create user success!'
export const DELETE_USER_SUCCESS = 'Remove user success!'
export const CHANGE_PASSWORD_SUCCESS = 'Change password success! You need login again'
export const UPDATE_USER_SUCCESS = 'Update user success!'

// confirm message
export const CONFIRM_BEFORE_LEAVE = 'Do you really want to leave? You have unsaved changes!'

// plan message
export const CREATE_PLAN_SUCCESS = 'Create plan success!'
export const UPDATE_PLAN_SUCCESS = 'Update plan success!'
export const DELETE_PLAN_SUCCESS = 'Delete plan success!'

// tnx message
export const ADD_REQUEST_TRANSFER_SUCCESS = 'Create request transfer success!'
export const APPROVE_REQUEST_TRANSFER_SUCCESS = 'Approve request transfer success!'
export const REJECT_REQUEST_TRANSFER_SUCCESS = 'Reject request transfer success!'

// d-app message
export const METAMASK_NOT_INSTALLED = 'No provider detected! Please install MetaMask!'
export const METAMASK_CONNECT_SUCCESS = 'MetaMask connected!'
export const METAMASK_NOT_CONNECTED = 'MetaMask is not connected!'
export const REQUEST_SENT_SUCCESS = 'Request has sent!'

// crowdfunding-admin
export const ADD_CROWDFUNDING_ADMIN_SUCCESS = 'Create crowdfunding admin success!'
export const UPDATE_CROWDFUNDING_ADMIN_SUCCESS = 'Update crowdfunding admin success!'

// program
export const UPDATE_PROGRAM_SUCCESS = 'Program updated!'

// swap-toko
export const APPROVE_SWAP_TOKO_SUCCESS = 'Approve success! We are now processing to transfer new TOKO!'
export const REJECT_SWAP_TOKO_SUCCESS = 'Reject success!'
export const MIGRATE_SWAP_TOKO_STAKINGS_SUCCESS = 'Migrate data success!'

// payment
export const REFUND_PAYMENT_ORDER_SUCCESS = 'Refund success!'
export const APPROVE_PAYMENT_ORDER_SUCCESS = 'Approve success!!'
export const REJECT_PAYMENT_ORDER_SUCCESS = 'Reject success!'
