import apiCaller from './api-caller'

const RESOURCES = {
  users: '/report/wallet/user',
  identity: '/identity-document'
}

export default {
  getUsers(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.users}/list`, params, headers })
  },

  getUser(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.users}/detail/${id}`, headers })
  },

  approveUser(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.identity}/approve`, data, headers })
  },

  rejectUser(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.identity}/reject`, data, headers })
  },

  getUserIdentifyImage(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.identity}/image/${id}`, headers, responseType: 'blob' })
  },

  checkUserIdentifyDocument(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.identity}/check`, params, headers })
  },

  updateUserIdentifyDocument(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.identity}/${id}`, data, headers })
  }
}
