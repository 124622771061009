<template>
  <div v-if="alert" :class="`alert alert--${alert.type} ${alertClass}`">
    {{ alert.content }}
    <button class="btn btn--link alert__action" @click="onCloseAlert"></button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Alert',

  data() {
    return {
      currTimeout: null
    }
  },

  props: {
    alertClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapState('alert', ['alert'])
  },

  methods: {
    ...mapMutations('alert', ['CLEAR_ALERT']),

    onCloseAlert() {
      this['CLEAR_ALERT']()
      window.clearTimeout(this.currTimeout)
    }
  },

  watch: {
    alert: function(newAlert) {
      if (newAlert && newAlert.timeOut) {
        this.currTimeout = setTimeout(() => {
          this['CLEAR_ALERT']()
        }, newAlert.timeOut)
      }
    }
  },

  beforeDestroy() {
    this['CLEAR_ALERT']()
    window.clearTimeout(this.currTimeout)
  }
}
</script>
