import dataSets from '../../support/data-sets'

const state = {
  reportReferrers: null,
  reportReferrer: null,
  referrerData: null,
  reportReferredUsers: null,
  assets: dataSets.initReferrersAssets
}

export default state
