import dataSets from '../../support/data-sets'

import {
  STORE_REPORT_REFERRERS,
  STORE_REPORT_REFERRER,
  STORE_REPORT_REFERRED_USERS,
  STORE_REFERRER_DATA,
  CLEAR_REPORT_REFERRER
} from '../mutation-types'

const mutations = {
  [STORE_REPORT_REFERRERS](state, payload) {
    state.reportReferrers = payload
  },

  [STORE_REPORT_REFERRER](state, payload) {
    state.reportReferrer = payload
  },

  [STORE_REPORT_REFERRED_USERS](state, payload) {
    state.reportReferredUsers = payload
  },

  [STORE_REFERRER_DATA](state, payload) {
    state.referrerData = payload
  },

  [CLEAR_REPORT_REFERRER](state) {
    state.reportReferrer = null
    state.reportReferredUsers = null
    state.referrerData = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initReferrersAssets
  }
}

export default mutations
