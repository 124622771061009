import Web3 from 'web3'
import TokoinToken from '@/contracts/TokoinToken'
import TokoinTokenBEP20 from '@/contracts/TokoinTokenBEP20'
import { authorizationHeader } from '@/store/support/authorization'
import SettingService from '@/services/setting'
import * as message from '@/store/support/message'
let eWeb3Instance, bscWeb3Instance, eTokoinTokenInstance, bscTokoinTokenInstance

const actions = {
  getAppVersions({ commit }) {
    return SettingService.getAppVersions(authorizationHeader()).then(response => {
      commit('STORE_APP_VERSIONS', response.data.result.data)

      return response
    })
  },

  updateAppVersion({ commit }, param) {
    const { id, version, url, platform } = param
    const requestData = { version, url, platform }

    return SettingService.updateAppVersion(id, requestData, authorizationHeader()).then(response => {
      commit('UPDATE_APP_VERSION', response.data.result.data)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.UPDATE_APP_VERSION_SUCCESS, timeOut: 2400 },
        { root: true }
      )

      return response
    })
  },

  getReferralSetting({ commit }) {
    return SettingService.getReferralSetting(authorizationHeader()).then(response => {
      commit('STORE_REFERRAL_SETTING', response.data.result.data)

      return response
    })
  },

  updateReferralSetting({ commit }, param) {
    const { id, value, description, key } = param
    const requestData = { value, description, key }

    return SettingService.updateReferralSetting(id, requestData, authorizationHeader()).then(response => {
      commit('UPDATE_REFERRAL_SETTING', response.data.result.data)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.UPDATE_REWARD_AMOUNT_SUCCESS, timeOut: 2400 },
        { root: true }
      )

      return response
    })
  },

  async getAdminAmounts({ dispatch }) {
    const admins = await SettingService.getAdminAmounts(authorizationHeader())

    // ethe network
    eWeb3Instance = new Web3(process.env.VUE_APP_INFURA_LINK || Web3.givenProvider)
    eTokoinTokenInstance = new eWeb3Instance.eth.Contract(
      TokoinToken.abi,
      process.env.VUE_APP_ETHERSCAN_TOKOIN_ADDRESS,
      {}
    )

    // bsc network
    bscWeb3Instance = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_BSC_RPC_URL))
    bscTokoinTokenInstance = new bscWeb3Instance.eth.Contract(
      TokoinTokenBEP20.abi,
      process.env.VUE_APP_BEP20_TOKOIN_TOKEN_ADDRESS,
      {}
    )
    // new web3Instance.eth.Contract(TokoinTokenBEP20.abi, process.env.VUE_APP_BEP20_TOKOIN_TOKEN_ADDRESS, {})

    dispatch('getAdminOfReferralSetting', admins.data.result.data)
  },

  getAdminOfReferralSetting({ commit }, data) {
    const scanDatas = data.map(async adminAMount => {
      // for eth network
      const eTokoBalanceData = await eTokoinTokenInstance.methods.balanceOf(adminAMount.address).call()
      const eEtherBalanceData = await eWeb3Instance.eth.getBalance(adminAMount.address)

      // for bsc network
      const bscTokoBalanceData = await bscTokoinTokenInstance.methods.balanceOf(adminAMount.address).call()
      const bnbBSCBalanceData = await bscWeb3Instance.eth.getBalance(adminAMount.address)

      return {
        eToko: eTokoBalanceData,
        eEther: eEtherBalanceData,
        bscToko: bscTokoBalanceData,
        bscBNB: bnbBSCBalanceData,
        ...adminAMount
      }
    })

    Promise.all(scanDatas).then(values => {
      commit('STORE_ADMIN_OF_REFERRAL_SETTING', values)
    })
  },

  getConvertCurrency({ commit }) {
    return SettingService.getConvertCurrency(authorizationHeader()).then(response => {
      commit('STORE_CONVERT_CURRENCY', response.data.result.data)

      return response
    })
  }
}

export default actions
