<template>
  <div class="main-wrapper">
    <app-sidebar @on-logout="isOpenConfirmLogout = true" />

    <main class="main">
      <app-header />

      <div class="main__content">
        <div class="container">
          <div class="main__content-inner">
            <slot />
          </div>
        </div>
      </div>
    </main>

    <confirm-dialog
      v-if="isOpenConfirmLogout"
      :open.sync="isOpenConfirmLogout"
      confirmText="Are you sure you want to log out?"
      confirmButtonText="Log out"
      @confirm="logout"
    />

    <alert />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppHeader from '@/components/AppHeader'
import AppSidebar from '@/components/AppSidebar'
import Alert from '@/components/Alert'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: 'MainLayout',

  data() {
    return {
      isOpenConfirmLogout: false
    }
  },

  components: {
    AppHeader,
    AppSidebar,
    Alert,
    ConfirmDialog
  },

  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>
