import { authorizationHeader } from '@/store/support/authorization'
import RequestLogService from '@/services/request-log'

const actions = {
  getRequestLogs({ commit }, params) {
    return RequestLogService.getRequestLogs(params, authorizationHeader()).then(response => {
      commit('STORE_REQUEST_LOGS', response.data.result)

      return response
    })
  },

  getRequestLog({ commit }, id) {
    return RequestLogService.getRequestLog(id, authorizationHeader()).then(response => {
      commit('STORE_REQUEST_LOG', response.data.result.data)

      return response
    })
  }
}

export default actions
