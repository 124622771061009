import apiCaller from './api-caller'

const RESOURCES = {
  payment: '/payment'
}

export default {
  getPaymentOrders(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.payment}/orders`, params, headers })
  },

  getPaymentOrder(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.payment}/orders/${id}`, headers })
  },

  getPaymentTransferAmountImage(orderId, headers) {
    return apiCaller({
      method: 'GET',
      url: `${RESOURCES.payment}/picture-bank-transfer/${orderId}`,
      headers,
      responseType: 'blob'
    })
  },

  approvePaymentOrder(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.payment}/approve`, data, headers })
  },

  rejectPaymentOrder(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.payment}/reject`, data, headers })
  },

  refundPaymentOrder(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.payment}/refund`, data, headers })
  },

  getPaymentTransactions(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.payment}/transactions`, params, headers })
  }
}
