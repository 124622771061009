import dataSets from '../../support/data-sets'

const state = {
  convertTokoOrders: null,
  convertTokoOrder: null,
  convertTokoTnxFrom: null,
  convertTokoTnxTo: null,
  convertTokoSettings: null,
  assets: dataSets.initConvertTokoAssets
}

export default state
