import moment from '@/moment'

class DateTimeFormat {
  constructor(dateContext) {
    this.fromDate = dateContext.startDate
    this.toDate = dateContext.endDate
    this.moment = moment
    this.initData = {
      anHour: 60,
      startOfDay: { hour: 0, minute: 0, seconds: 0 },
      endOfDay: { hour: 23, minute: 59, seconds: 59 }
    }
    this.today = this.moment().format('LL')
  }

  getCurrentTimeFormat() {
    return this.moment().format('LLL')
  }

  getDateCorrectTimezone(date) {
    const timeZone = -(date.getTimezoneOffset() / this.initData.anHour)

    if (date.getHours() < timeZone) return date.setHours(timeZone)
    return date
  }

  formatStartOfDay(date) {
    return this.moment(date).set(this.initData.startOfDay).format('LLL')
  }

  formatEndOfDay(date) {
    return this.moment(date).set(this.initData.endOfDay).format('LLL')
  }

  getFromDateFormat() {
    const startDateCorrectTimezone = this.getDateCorrectTimezone(this.fromDate)
    const dateFormat = this.moment(startDateCorrectTimezone).format('LL')
    const fromDate = this.moment(dateFormat).isBefore(this.today)
      ? this.formatStartOfDay(startDateCorrectTimezone)
      : this.formatStartOfDay(this.fromDate)

    return fromDate
  }

  getToDateFormat() {
    const endDateCorrectTimezone = this.getDateCorrectTimezone(this.toDate)
    const dateFormat = this.moment(endDateCorrectTimezone).format('LL')
    const toDate = this.moment(dateFormat).isSame(this.today)
      ? this.getCurrentTimeFormat()
      : this.formatEndOfDay(endDateCorrectTimezone)

    return toDate
  }

  getDateRequest() {
    return {
      fromDate: this.getFromDateFormat(),
      toDate: this.getToDateFormat()
    }
  }
}

export default function formatDateTime(dateContext) {
  return new DateTimeFormat(dateContext)
}
