import dataSets from '../../support/data-sets'

const state = {
  goldInvestmentInfo: null,
  goldInvestmentSettings: null,
  goldInvestmentRequirements: null,
  goldInvestmentMarkupFees: null,
  goldInvestmentOrders: null,
  goldInvestmentOrder: null,
  goldInvestmentOrderAssets: dataSets.initGoldInvestmentOrderAssets,
  goldInvestmentWithdraws: null,
  goldInvestmentWithdraw: null,
  goldInvestmentWithdrawAssets: dataSets.initGoldInvestmentWithdrawAssets
}

export default state
