import apiCaller from './api-caller'

const RESOURCES = {
  requestLog: '/request-log'
}

export default {
  getRequestLogs(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.requestLog}/list`, params, headers })
  },

  getRequestLog(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.requestLog}/read/${id}`, headers })
  }
}
