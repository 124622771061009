import moment from 'moment-timezone'

moment.tz.setDefault('UTC')
moment.updateLocale('en', {
  longDateFormat: {
    LL: 'YYYY-MM-DD',
    LLL: 'YYYY-MM-DD HH:mm:ss',
    LTS: 'h:mm A, M/D/YYYY'
  }
})

export default moment
