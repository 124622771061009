import apiCaller from './api-caller'

const RESOURCES = {
  stakingUsers: '/report/staking/users',
  programDetail: '/program/detail',
  stakingSetting: '/program/staking/setting',
  stakingUser: '/program/staking/user'
}

export default {
  getStakingUsers(params, headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.stakingUsers, params, headers })
  },

  getStakingPlans(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.programDetail}/${id}/plans`, headers })
  },

  getStakingPlan(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.programDetail}/${params.id}/plans/${params.planId}`, headers })
  },

  getStakingSettings(headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.stakingSetting, headers })
  },

  updateStakingSetting(data, headers) {
    return apiCaller({ method: 'PUT', url: RESOURCES.stakingSetting, data, headers })
  },

  approveUserJoinStaking(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.stakingUser}/approve`, data, headers })
  }
}
