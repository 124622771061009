import { REPORT_DATA_SETS } from '@/utils/data-sets'

const state = {
  reportBalanceSummary: {
    totalBalance: 0,
    totalBalanceInPlans: 0,
    totalInvestors: 0,
    totalToDeposit: 0
  },
  reportBalanceDataPlans: null,
  reportBalanceDataChart: null,
  reportJoinedUsersDataPlans: null,
  reportJoinedUsersDataChart: null,
  reportDataKeys: null,
  reportDataSets: REPORT_DATA_SETS,
  reportRegisterChart: null,
  reportPaymentOrder: null
}

export default state
