import { STORE_PROFILE, STORE_ACCESS_TOKEN, CLEAR_AUTH_DATA } from '../mutation-types'

const mutations = {
  [STORE_PROFILE](state, payload) {
    state.currentUser = payload
  },

  [STORE_ACCESS_TOKEN](state, payload) {
    state.accessToken = payload
  },

  [CLEAR_AUTH_DATA](state) {
    state.currentUser = null
    state.accessToken = ''
  }
}

export default mutations
