import apiCaller from './api-caller'

const RESOURCES = {
  swapToko: '/swap-toko'
}

export default {
  getSwapTokos(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.swapToko}/user-transfer`, params, headers })
  },

  getSwapToko(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.swapToko}/user-transfer/${id}`, headers })
  },

  rejectSwapToko(data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.swapToko}/user-transfer/reject`, data, headers })
  },

  approveSwapToko(data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.swapToko}/user-transfer/approve`, data, headers })
  },

  getSwapTokoStakings(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.swapToko}/staking`, params, headers })
  },

  getSwapTokoStaking(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.swapToko}/staking/${id}`, headers })
  },

  rejectSwapTokoStaking(data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.swapToko}/staking/reject`, data, headers })
  },

  approveSwapTokoStaking(data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.swapToko}/staking/approve`, data, headers })
  },

  triggerSwapTokoStakings(headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.swapToko}/active-swap-toko-staking`, headers })
  }
}
