import 'formdata-polyfill'
import ProgramService from '@/services/program'
import ReportService from '@/services/report'
import { PROGRAM_TYPE } from '@/utils/key-mapping'
import { authorizationHeader } from '@/store/support/authorization'
import * as message from '@/store/support/message'

const actions = {
  getProgramBalances({ commit }, params) {
    return ReportService.getReportBalanceSummary(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_PROGRAM_BALANCES', response.data.result.data)

      return response
    })
  },

  getProgramList({ commit }) {
    return ProgramService.getProgramList(authorizationHeader()).then(response => {
      const { data } = response.data.result
      commit('STORE_PROGRAM_LIST', data)

      for (let i = 0; i < data.length; i++) {
        if (data[i].programType === PROGRAM_TYPE.crowdfunding) {
          commit('crowdfunding/STORE_CROWDFUNDING_INFO', data[i], { root: true })
        }

        if (data[i].programType === PROGRAM_TYPE.staking) {
          commit('staking/STORE_STAKING_INFO', data[i], { root: true })
        }

        if (data[i].programType === PROGRAM_TYPE.goldInvestment) {
          commit('goldInvestment/STORE_GOLD_INVESTMENT_INFO', data[i], { root: true })
        }
      }

      return response
    })
  },

  updateProgram({ commit }, data) {
    let formData = new FormData()
    Object.keys(data).forEach(key => formData.set(`${key}`, data[key] || ''))

    return ProgramService.updateProgram(data.id, formData, authorizationHeader()).then(response => {
      const data = response.data.result.data
      if (data.programType === PROGRAM_TYPE.crowdfunding) {
        commit('crowdfunding/UPDATE_CROWDFUNDING_INFO', response.data.result.data, { root: true })
      }

      if (data.programType === PROGRAM_TYPE.staking) {
        commit('staking/UPDATE_STAKING_INFO', response.data.result.data, { root: true })
      }

      if (data.programType === PROGRAM_TYPE.goldInvestment) {
        commit('goldInvestment/UPDATE_GOLD_INVESTMENT_INFO', response.data.result.data, { root: true })
      }

      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.UPDATE_PROGRAM_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  }
}

export default actions
