import TransactionService from '@/services/transaction'
import UserService from '@/services/user'
import router from '@/router'
import { authorizationHeader } from '@/store/support/authorization'
import * as message from '../../support/message'

const actions = {
  getTransactions({ commit }, params) {
    return TransactionService.getTransactions(params, authorizationHeader()).then(response => {
      commit('STORE_TRANSACTIONS', response.data.result)

      return response
    })
  },

  getTransaction({ commit }, params) {
    return TransactionService.getTransactions(params, authorizationHeader()).then(async response => {
      //FIXME: replace this logic
      if (response.data.result.data) {
        const tnx = response.data.result.data
        const userID = tnx[0].userId
        let userData
        if (userID > 0) {
          userData = await UserService.getUser(userID, authorizationHeader())
        } else {
          if (tnx[0].optionsData && tnx[0].optionsData.crowdfundingFeed) {
            userData = await UserService.getUser(tnx[0].optionsData.crowdfundingFeed.userId, authorizationHeader())
          } else {
            userData = { data: { result: { user: { email: 'admin' } } } }
          }
        }

        commit('STORE_TRANSACTION', { ...tnx[0], user: userData.data.result.data })
      } else {
        commit('STORE_TRANSACTION', null)
      }

      return response
    })
  },

  addTransaction({ commit }, data) {
    return TransactionService.addTransaction(data, authorizationHeader()).then(response => {
      router.push({ name: 'Withdraw' })
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.REQUEST_SENT_SUCCESS, timeOut: 2400 },
        { root: true }
      )

      return response
    })
  }
}

export default actions
