import apiCaller from './api-caller'

const RESOURCES = {
  smartContract: '/smart-contract'
}

export default {
  getSmartContracts(headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.smartContract, headers })
  }
}
