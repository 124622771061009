import { authorizationHeader } from '@/store/support/authorization'
import PaymentService from '@/services/payment'
import * as messages from '@/store/support/message'

const actions = {
  getPaymentOrders({ commit }, params) {
    return PaymentService.getPaymentOrders(params, authorizationHeader()).then(response => {
      commit('STORE_PAYMENT_ORDERS', response.data.result)

      return response
    })
  },

  getPaymentOrder({ commit, dispatch }, id) {
    return PaymentService.getPaymentOrder(id, authorizationHeader()).then(response => {
      const { data } = response.data.result
      commit('STORE_PAYMENT_ORDER', data)

      // get tnx if need
      if (data && data.tnxHash) {
        dispatch('transaction/getTransaction', { tnxHash: data.tnxHash }, { root: true })
      }

      // get bank transfer image
      if (data && data.picBankTransferUrl) {
        dispatch('getPaymentTransferAmountImage', data.id)
      }

      return response
    })
  },

  getPaymentTransferAmountImage({ commit }, orderId) {
    return PaymentService.getPaymentTransferAmountImage(orderId, authorizationHeader()).then(response => {
      const imageBlob = URL.createObjectURL(response.data)
      commit('STORE_PAYMENT_TRANSFER_AMOUNT_IMAGE', imageBlob)

      return response
    })
  },

  approvePaymentOrder({ commit }, data) {
    return PaymentService.approvePaymentOrder(data, authorizationHeader()).then(response => {
      commit('APPROVE_PAYMENT_ORDER', data.adminNote)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: messages.APPROVE_PAYMENT_ORDER_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  rejectPaymentOrder({ commit }, data) {
    return PaymentService.rejectPaymentOrder(data, authorizationHeader()).then(response => {
      commit('REJECT_PAYMENT_ORDER', data.adminNote)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: messages.REJECT_PAYMENT_ORDER_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  refundPaymentOrder({ commit }, data) {
    return PaymentService.refundPaymentOrder(data, authorizationHeader()).then(response => {
      commit('REFUND_PAYMENT_ORDER', data.adminNote)
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: messages.REFUND_PAYMENT_ORDER_SUCCESS, timeOut: 1800 },
        { root: true }
      )

      return response
    })
  },

  exportPaymentOrders({ commit }) {
    const params = {
      limit: 999999,
      order_by: 'asc',
      sort_by: 'created_at'
    }

    return PaymentService.getPaymentOrders(params, authorizationHeader()).then(response => {
      commit('STORE_PAYMENT_ORDERS_EXPORT_DATA', response?.data?.result?.data)
      return response
    })
  }
}

export default actions
