import { mapState } from 'vuex'
import { SYS_USER_ROLE } from '@/utils/key-mapping'

export default {
  computed: {
    ...mapState('auth', ['currentUser']),

    sysUserRole() {
      let role = ''

      if (this.currentUser && this.currentUser.role === SYS_USER_ROLE.admin) {
        role = 'Administrator'
      }

      if (this.currentUser && this.currentUser.role === SYS_USER_ROLE.member) {
        role = 'Member'
      }

      return role
    }
  }
}
