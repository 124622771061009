export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggle() {
      this.$emit('update:open', !this.open)
    }
  }
}
