import { isObjEqual } from './json-parser'

class PlanValidation {
  constructor(planItems) {
    this.planItems = PlanValidation.parsePlanItems(planItems)
    this.planItem = {
      rate: '',
      min_amount: '',
      max_amount: '',
      error: null,
      disable: false,
      index: ''
    }
    this.planErrorMess = {
      numeric: 'The value must be a number',
      empty: 'Please input a value',
      maxSmallerThanMin: 'Max. amount must be larger than min. amount',
      minLargerThanPrevMax: 'Min. amount must be larger than max. amount of previous row'
    }
  }

  static parsePlanItems(items) {
    return items.map((item, index) => {
      return {
        rate: item.rate,
        min_amount: item.min_amount,
        max_amount: item.max_amount,
        error: null,
        disable: false,
        index
      }
    })
  }

  clearCurrentError(row) {
    row.error = null
  }

  isRowNull(row) {
    // revert index to empty string
    const cloneRow = { ...row, index: '' }
    // check row is null
    return isObjEqual(cloneRow, this.planItem)
  }

  initRowError(row) {
    if (row.error) return
    row.error = {}
  }

  checkRowItemIsNumeric(row) {
    this.initRowError(row)

    if (isNaN(row.rate)) row.error['rate'] = this.planErrorMess.numeric
    if (isNaN(row.min_amount)) row.error['min_amount'] = this.planErrorMess.numeric
    if (isNaN(row.max_amount)) row.error['max_amount'] = this.planErrorMess.numeric

    // check previous item is filled?
    this.checkPreviousRowsFullFilled(row.index)
  }

  checkRowFullFilled(row) {
    if (this.isRowNull(row)) return
    this.initRowError(row)

    if (!row.rate) row.error['rate'] = this.planErrorMess.empty
    if (!row.min_amount) row.error['min_amount'] = this.planErrorMess.empty
    if (!row.max_amount) row.error['max_amount'] = this.planErrorMess.empty

    // check value is number
    this.checkRowItemIsNumeric(row)

    // check previous item is filled?
    this.checkPreviousRowsFullFilled(row.index)
  }

  checkRowAmount(row) {
    if (this.isRowNull(row)) return
    // min_amount && max_amount required and min_amount have to smaller than max_amount
    if (row.min_amount && row.max_amount && parseInt(row.max_amount) <= parseInt(row.min_amount)) {
      this.initRowError(row)
      row.error['max_amount'] = this.planErrorMess.maxSmallerThanMin
    }

    this.checkPreviousRowAmount(row)
  }

  checkPreviousRowAmount(row) {
    const rowIndex = row.index
    if (rowIndex <= 0) return

    const previousRow = this.planItems[rowIndex - 1]
    if (this.isRowNull(previousRow)) return

    // prev max_amount && current min_amount required and prev max_amount have to smaller than current min_amount
    if (previousRow.max_amount && row.min_amount && parseInt(row.min_amount) <= parseInt(previousRow.max_amount)) {
      this.initRowError(row)
      row.error['min_amount'] = this.planErrorMess.minLargerThanPrevMax
    }
  }

  checkPreviousRowsFullFilled(index) {
    const currIndex = index
    // recursion for check previous curr index item is filled
    if (currIndex <= 0) return

    const previousRowIndex = index - 1
    const itemPos = this.planItems.findIndex(item => item.index === previousRowIndex)
    if (itemPos >= 0 && !this.planItems[itemPos].error) {
      this.planItems[itemPos].error = {
        rate: this.planErrorMess.empty,
        min_amount: this.planErrorMess.empty,
        max_amount: this.planErrorMess.empty
      }
    }

    return this.checkPreviousRowsFullFilled(currIndex - 1)
  }

  validate() {
    for (let i = 0; i < this.planItems.length; i++) {
      // clear all current error
      this.clearCurrentError(this.planItems[i])

      this.checkRowFullFilled(this.planItems[i])
      this.checkRowAmount(this.planItems[i])
    }

    return this.planItems
  }
}

export default function PlanValidate(planItems) {
  return new PlanValidation(planItems)
}
