import StakingService from '@/services/staking'
import { authorizationHeader } from '@/store/support/authorization'

const actions = {
  getStakingUsers({ commit }, params) {
    return StakingService.getStakingUsers(params, authorizationHeader()).then(response => {
      commit('STORE_STAKING_USERS', response.data.result)

      return response
    })
  },

  getStakingUser({ commit }, params) {
    return StakingService.getStakingUsers(params, authorizationHeader()).then(response => {
      commit('STORE_STAKING_USER', response.data.result.data[0])

      return response
    })
  },

  async getStakingPlans({ commit, state, dispatch }) {
    await dispatch('program/getProgramList', {}, { root: true })
    const programId = state.stakingInfo.id

    return StakingService.getStakingPlans(programId, authorizationHeader()).then(response => {
      commit('STORE_STAKING_PLANS', response.data.result)

      return response
    })
  },

  async getStakingPlan({ commit, dispatch, state }, planId) {
    await dispatch('program/getProgramList', {}, { root: true })
    const programId = state.stakingInfo.id

    return StakingService.getStakingPlan({ planId, id: programId }, authorizationHeader()).then(response => {
      commit('STORE_STAKING_PLAN', response.data.result.data)

      return response
    })
  },

  getStakingSettings({ commit }) {
    return StakingService.getStakingSettings(authorizationHeader()).then(response => {
      commit('STORE_STAKING_SETTINGS', response.data.result.data)

      return response
    })
  },

  updateStakingSetting({ commit }, settingInfo) {
    return StakingService.updateStakingSetting(settingInfo, authorizationHeader()).then(response => {
      commit('STORE_STAKING_SETTING', settingInfo)
      commit('alert/STORE_ALERT', { type: 'success', content: 'Update Successfully', timeOut: 2400 }, { root: true })

      return response
    })
  },

  approveUserJoinStaking({ commit }, payload) {
    return StakingService.approveUserJoinStaking(payload, authorizationHeader()).then(response => {
      commit('user/STORE_USER', response.data.result.data.user, { root: true })
      commit('alert/STORE_ALERT', { type: 'success', content: 'Update Successfully', timeOut: 2400 }, { root: true })

      return response
    })
  }
}

export default actions
