import dataSets from '../../support/data-sets'
import { STORE_SWAP_TOKOS, CLEAR_SWAP_TOKOS_DATA, STORE_SWAP_TOKO, CLEAR_SWAP_TOKO_DATA } from '../mutation-types'

const mutations = {
  [STORE_SWAP_TOKOS](state, payload) {
    state.swapTokos = payload
  },

  [CLEAR_SWAP_TOKOS_DATA](state) {
    state.swapTokos = null
  },

  [STORE_SWAP_TOKO](state, payload) {
    state.swapToko = payload
  },

  [CLEAR_SWAP_TOKO_DATA](state) {
    state.swapToko = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initSwapTokoAssets
  }
}

export default mutations
