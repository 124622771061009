import apiCaller from './api-caller'

const RESOURCES = {
  requestTransfer: '/crowdfunding/request-transfer',
  admin: '/crowdfunding/admin'
}

export default {
  addRequestTransfer(data, headers) {
    return apiCaller({ method: 'POST', url: RESOURCES.requestTransfer, data, headers })
  },

  getRequestTransfers(params, headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.requestTransfer, params, headers })
  },

  getRequestTransfer(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.requestTransfer}/${id}`, headers })
  },

  approveRequestTransfer(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.requestTransfer}/approve`, data, headers })
  },

  rejectRequestTransfer(data, headers) {
    return apiCaller({ method: 'POST', url: `${RESOURCES.requestTransfer}/reject`, data, headers })
  },

  addCrowdfundingAdmin(data, headers) {
    return apiCaller({ method: 'POST', url: RESOURCES.admin, data, headers })
  },

  getCrowdfundingAdmins(headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.admin, headers })
  },

  getCrowdfundingAdmin(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.admin}/${id}`, headers })
  },

  updateCrowdfundingAdmin(id, data, headers) {
    return apiCaller({ method: 'PUT', url: `${RESOURCES.admin}/${id}`, data, headers })
  }
}
