import {
  STORE_REPORT_BALANCE_SUMMARY,
  STORE_REPORT_BALANCE_CHART,
  STORE_REPORT_JOINED_USERS_CHART,
  STORE_REPORT_REGISTER_CHART,
  STORE_REPORT_PAYMENT_ORDER_SUMMARY
} from '../mutation-types'

const mutations = {
  [STORE_REPORT_BALANCE_SUMMARY](state, payload) {
    state.reportBalanceSummary = payload
  },

  [STORE_REPORT_BALANCE_CHART](state, payload) {
    state.reportBalanceDataPlans = payload.dataPlans
    state.reportBalanceDataChart = payload.dataChart
    state.reportDataKeys = Object.keys(payload.dataPlans)
  },

  [STORE_REPORT_JOINED_USERS_CHART](state, payload) {
    state.reportJoinedUsersDataPlans = payload.dataPlans
    state.reportJoinedUsersDataChart = payload.dataChart
    state.reportDataKeys = Object.keys(payload.dataPlans)
  },

  [STORE_REPORT_REGISTER_CHART](state, payload) {
    state.reportRegisterChart = payload
  },

  [STORE_REPORT_PAYMENT_ORDER_SUMMARY](state, payload) {
    state.reportPaymentOrder = payload
  }
}

export default mutations
