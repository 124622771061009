import dataSets from '../../support/data-sets'

import {
  STORE_CONVERT_TOKO_ORDERS,
  STORE_CONVERT_TOKO_ORDER,
  STORE_CONVERT_TOKO_TNX_FROM,
  STORE_CONVERT_TOKO_TNX_TO,
  CLEAR_CONVERT_TOKO_ORDER,
  STORE_CONVERT_TOKO_SETTINGS,
  UPDATE_CONVERT_TOKO_SETTING
} from '../mutation-types'

const mutations = {
  [STORE_CONVERT_TOKO_ORDERS](state, payload) {
    state.convertTokoOrders = payload
  },

  [STORE_CONVERT_TOKO_ORDER](state, payload) {
    state.convertTokoOrder = payload
  },

  [STORE_CONVERT_TOKO_TNX_FROM](state, payload) {
    state.convertTokoTnxFrom = payload
  },

  [STORE_CONVERT_TOKO_TNX_TO](state, payload) {
    state.convertTokoTnxTo = payload
  },

  [CLEAR_CONVERT_TOKO_ORDER](state) {
    state.convertTokoOrder = null
    state.convertTokoTnxFrom = null
    state.convertTokoTnxTo = null
  },

  ['STORE_ASSETS'](state, payload) {
    state.assets = payload
  },

  ['CLEAR_ASSETS'](state) {
    state.assets = dataSets.initConvertTokoAssets
  },

  [STORE_CONVERT_TOKO_SETTINGS](state, payload) {
    state.convertTokoSettings = payload
  },

  [UPDATE_CONVERT_TOKO_SETTING](state, payload) {
    const index = state.convertTokoSettings.findIndex(info => info.id === payload.id)
    if (index < 0) return

    state.convertTokoSettings[index] = payload
  }
}

export default mutations
