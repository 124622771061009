import dataSets from '../../support/data-sets'
import {
  STORE_GOLD_INVESTMENT_INFO,
  UPDATE_GOLD_INVESTMENT_INFO,
  STORE_GOLD_INVESTMENT_SETTINGS,
  UPDATE_GOLD_INVESTMENT_SETTING,
  STORE_GOLD_INVESTMENT_REQUIREMENTS,
  ADD_GOLD_INVESTMENT_REQUIREMENT,
  UPDATE_GOLD_INVESTMENT_REQUIREMENT,
  DELETE_GOLD_INVESTMENT_REQUIREMENT,
  STORE_GOLD_INVESTMENT_MARKUP_FEES,
  ADD_GOLD_INVESTMENT_MARKUP_FEE,
  UPDATE_GOLD_INVESTMENT_MARKUP_FEE,
  DELETE_GOLD_INVESTMENT_MARKUP_FEE,
  STORE_GOLD_INVESTMENT_ORDERS,
  STORE_GOLD_INVESTMENT_ORDER,
  CLEAR_GOLD_INVESTMENT_ORDER,
  STORE_GOLD_INVESTMENT_WITHDRAWS,
  STORE_GOLD_INVESTMENT_WITHDRAW,
  CLEAR_GOLD_INVESTMENT_WITHDRAW,
  APPROVE_GOLD_INVESTMENT_WITHDRAW,
  REJECT_GOLD_INVESTMENT_WITHDRAW
} from '../mutation-types'

const mutations = {
  [STORE_GOLD_INVESTMENT_INFO](state, payload) {
    state.goldInvestmentInfo = payload
  },

  [UPDATE_GOLD_INVESTMENT_INFO](state, payload) {
    state.goldInvestmentInfo = payload
  },

  [STORE_GOLD_INVESTMENT_SETTINGS](state, payload) {
    state.goldInvestmentSettings = payload
  },

  [UPDATE_GOLD_INVESTMENT_SETTING](state, payload) {
    const index = state.goldInvestmentSettings.findIndex(item => item.id === payload.id)
    if (index < 0) return

    state.goldInvestmentSettings.splice(index, 1, payload)
  },

  [STORE_GOLD_INVESTMENT_REQUIREMENTS](state, payload) {
    state.goldInvestmentRequirements = payload
  },

  [ADD_GOLD_INVESTMENT_REQUIREMENT](state, payload) {
    state.goldInvestmentRequirements.push(payload)
  },

  [UPDATE_GOLD_INVESTMENT_REQUIREMENT](state, payload) {
    const index = state.goldInvestmentRequirements.findIndex(item => item.id === payload.id)
    if (index < 0) return

    state.goldInvestmentRequirements.splice(index, 1, payload)
  },

  [DELETE_GOLD_INVESTMENT_REQUIREMENT](state, id) {
    const index = state.goldInvestmentRequirements.findIndex(item => item.id === id)
    if (index < 0) return

    state.goldInvestmentRequirements.splice(index, 1)
  },

  [STORE_GOLD_INVESTMENT_MARKUP_FEES](state, payload) {
    state.goldInvestmentMarkupFees = payload
  },

  [ADD_GOLD_INVESTMENT_MARKUP_FEE](state, payload) {
    state.goldInvestmentMarkupFees.push(payload)
  },

  [UPDATE_GOLD_INVESTMENT_MARKUP_FEE](state, payload) {
    const index = state.goldInvestmentMarkupFees.findIndex(item => item.id === payload.id)
    if (index < 0) return

    state.goldInvestmentMarkupFees.splice(index, 1, payload)
  },

  [DELETE_GOLD_INVESTMENT_MARKUP_FEE](state, id) {
    const index = state.goldInvestmentMarkupFees.findIndex(item => item.id === id)
    if (index < 0) return

    state.goldInvestmentMarkupFees.splice(index, 1)
  },

  [STORE_GOLD_INVESTMENT_ORDERS](state, payload) {
    state.goldInvestmentOrders = payload
  },

  [STORE_GOLD_INVESTMENT_ORDER](state, payload) {
    state.goldInvestmentOrder = payload
  },

  [CLEAR_GOLD_INVESTMENT_ORDER](state) {
    state.goldInvestmentOrder = null
  },

  [STORE_GOLD_INVESTMENT_WITHDRAWS](state, payload) {
    state.goldInvestmentWithdraws = payload
  },

  [STORE_GOLD_INVESTMENT_WITHDRAW](state, payload) {
    state.goldInvestmentWithdraw = payload
  },

  [APPROVE_GOLD_INVESTMENT_WITHDRAW](state, payload) {
    state.goldInvestmentWithdraw = payload
  },

  [REJECT_GOLD_INVESTMENT_WITHDRAW](state, payload) {
    state.goldInvestmentWithdraw = payload
  },

  [CLEAR_GOLD_INVESTMENT_WITHDRAW](state) {
    state.goldInvestmentWithdraw = null
  },

  ['STORE_GOLD_INVESTMENT_ORDER_ASSETS'](state, payload) {
    state.goldInvestmentOrderAssets = payload
  },

  ['CLEAR_GOLD_INVESTMENT_ORDER_ASSETS'](state) {
    state.goldInvestmentOrderAssets = dataSets.initGoldInvestmentOrderAssets
  },

  ['STORE_GOLD_INVESTMENT_WITHDRAW_ASSETS'](state, payload) {
    state.goldInvestmentWithdrawAssets = payload
  },

  ['CLEAR_GOLD_INVESTMENT_WITHDRAW_ASSETS'](state) {
    state.goldInvestmentWithdrawAssets = dataSets.initGoldInvestmentWithdrawAssets
  }
}

export default mutations
