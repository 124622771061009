import Vue from 'vue'
import bigInt from 'big-integer'
import moment from '@/moment'
import {
  STATUS,
  TNX_STATUS,
  BLACKLIST_TYPE,
  PAYMENT_TYPE,
  PAYMENT_CRYPTO_TRANSFER_STATUS,
  REQUEST_LOG_TYPES
} from '@/utils/key-mapping'

Vue.filter('number_with_commas', value => {
  const numberFormatted = value
    ? parseInt(value)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0
  return numberFormatted
})
Vue.filter('float_number_with_commas', value => {
  const floatNumberFormatted = value
    ? parseFloat(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0
  return floatNumberFormatted
})
Vue.filter('numeric_float_number_with_commas', value => {
  if (!value) return 0
  const floatNumberFormatted = value
    ? parseFloat(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0
  return floatNumberFormatted
})
Vue.filter('rate_percent', value => (value > 0 ? (value / 100).toFixed(2) : 0))
Vue.filter('format_user_count_text', value => (value > 1 ? `${value} users` : `${value} user`))
Vue.filter('format_verified_text', value => {
  if (value === 0) return 'not verified'
  if (value === 1) return 'in reviewing'
  if (value === 2) return 'verified'
  if (value === 3) return 'verified fail'
  return 'invalid'
})
Vue.filter('format_transaction_status_text', value => {
  if (value === TNX_STATUS.pending) return 'pending'
  if (value === TNX_STATUS.success) return 'success'
  if (value === TNX_STATUS.failed) return 'failed'
  if (value === TNX_STATUS.error) return 'error'
  if (value === TNX_STATUS.notFound) return 'not found'
  if (value === TNX_STATUS.symbolNotFound) return 'symbol not found'
  if (value === TNX_STATUS.btcUnconfirmed) return 'unconfirmed'
  return 'invalid'
})
Vue.filter('format_payment_crypto_transfer_status_text', value => {
  if (value === PAYMENT_CRYPTO_TRANSFER_STATUS.pending) return 'pending'
  if (value === PAYMENT_CRYPTO_TRANSFER_STATUS.success) return 'success'
  if (value === PAYMENT_CRYPTO_TRANSFER_STATUS.failed) return 'failed'
  return 'error'
})
Vue.filter('format_transaction_status_class', value => {
  if (parseInt(value) === TNX_STATUS.pending) return 'text-warning'
  if (parseInt(value) === TNX_STATUS.success) return 'text-success'
  return 'text-error'
})
Vue.filter('format_payment_crypto_transfer_status_class', value => {
  if (parseInt(value) === PAYMENT_CRYPTO_TRANSFER_STATUS.pending) return 'text-warning'
  if (parseInt(value) === PAYMENT_CRYPTO_TRANSFER_STATUS.success) return 'text-success'
  return 'text-error'
})
Vue.filter('format_transaction_count_text', value => (value > 1 ? `${value} transactions` : `${value} transaction`))
Vue.filter('format_month_count_text', value => (value > 1 ? `${value} months` : `${value} month`))
Vue.filter('format_request_count_text', value => (value > 1 ? `${value} requests` : `${value} request`))
Vue.filter('format_admin_count_text', value => (value > 1 ? `${value} admins` : `${value} admin`))
Vue.filter('format_status_text', value => (value === STATUS.active ? 'Active' : 'Inactive'))
Vue.filter('format_order_count_text', value => (value > 1 ? `${value} orders` : `${value} order`))
Vue.filter('format_transaction_wallet_type', value => {
  if (value.toUpperCase() === 'BTC') return 'Bitcoin (BTC)'
  if (value.toUpperCase() === 'TOKO') return 'Tokoin (TOKO)'
  if (value.toUpperCase() === 'ETH') return 'Ethereum (ETH)'
  if (value.toUpperCase() === 'USDT') return 'Tether (USDT)'
})
Vue.filter('format_big_int', value => {
  const divModValue = bigInt(value).divmod(10 ** 18)
  return `${divModValue.quotient.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${divModValue.remainder}`
})
Vue.filter('format_document_code', value => (value ? value : 'Not Available'))
Vue.filter('moment_l', value => `${moment(value).format('L')} UTC`)
Vue.filter('moment_lts', value => `${moment(value).format('LTS')} UTC`)
Vue.filter('format_bo_user_role_text', value => {
  if (value === 1) return 'Super Admin'
  if (value === 2) return 'Admin'
  return 'Member'
})
Vue.filter('format_blacklist_type', value => {
  if (value === BLACKLIST_TYPE.hacker) return 'HACKER'
  return ''
})
Vue.filter('format_payment_type', value => {
  if (value === PAYMENT_TYPE.creditCard) return 'Visa / Mastercard'
  if (value === PAYMENT_TYPE.bankTransfer) return 'Bank Transfer'
  if (value === PAYMENT_TYPE.echannel) return 'E-Channel'

  return 'invalid'
})
Vue.filter('format_request_log_type_text', value => {
  switch (value) {
    case REQUEST_LOG_TYPES.referral:
      return 'referral'
    case REQUEST_LOG_TYPES.crowdfundingFeed:
      return 'crowdfunding feed'
    case REQUEST_LOG_TYPES.crowdfundingInterestRate:
      return 'crowdfunding interest rate'
    case REQUEST_LOG_TYPES.swapToko:
      return 'swap toko'
    case REQUEST_LOG_TYPES.paymentTransfer:
      return 'payment transfer'
    case REQUEST_LOG_TYPES.convertToko:
      return 'convert toko'
    default:
      return 'invalid'
  }
})
