import apiCaller from './api-caller'

const RESOURCES = {
  convertTokoOrder: '/convert-toko/orders',
  convertTokoSetting: '/convert-toko/settings'
}

export default {
  getConvertTokoOrders(params, headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.convertTokoOrder, params, headers })
  },

  getConvertTokoOrder(id, headers) {
    return apiCaller({ method: 'GET', url: `${RESOURCES.convertTokoOrder}/${id}`, headers })
  },

  getConvertTokoSettings(headers) {
    return apiCaller({ method: 'GET', url: RESOURCES.convertTokoSetting, headers })
  },

  updateConvertTokoSetting(data, headers) {
    return apiCaller({ method: 'PUT', url: RESOURCES.convertTokoSetting, headers, data })
  }
}
