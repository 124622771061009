import { COMMON_LINE_CHART_OPTIONS, COMMON_BAR_CHART_OPTIONS, PAYMENT_CRYPTO_TYPE_DATA } from '@/utils/data-sets'
import { deepCopy } from '@/utils/json-parser'

const getters = {
  formatStakingDataChart: state => type => {
    // type: balance | users
    const dataChart = type === 'balance' ? state.reportBalanceDataChart : state.reportJoinedUsersDataChart
    if (!dataChart || !dataChart.dataSets) return

    const formatDataSets = deepCopy(state.reportDataKeys)
      .reverse()
      .map((key, index) => {
        let newData
        for (let i = 0; i < dataChart.dataSets.length; i++) {
          if (key.toUpperCase() === dataChart.dataSets[i].label.replaceAll(' ', '').toUpperCase()) {
            newData = {
              ...COMMON_LINE_CHART_OPTIONS,
              ...state.reportDataSets[index],
              ...dataChart.dataSets[i]
            }
          }
        }

        return newData
      })

    return {
      labels: dataChart.labels.filter(label => label),
      datasets: formatDataSets.filter(data => data)
    }
  },

  formatStakingDataStatistics: state => type => {
    // type: balance | users
    const dataStatistics = type === 'balance' ? state.reportBalanceDataPlans : state.reportJoinedUsersDataPlans
    if (!dataStatistics) return

    const newDataStatistic = deepCopy(state.reportDataKeys)
      .reverse()
      .map((key, index) => {
        return {
          ...state.reportDataSets[index],
          ...dataStatistics[key],
          title: key
        }
      })

    return newDataStatistic
  },

  formatReportDataStatistics: state => {
    if (!state.reportRegisterChart) return

    const registerReport = deepCopy(state.reportRegisterChart)
    const dataStatistic = registerReport.dataStatistics.map((data, index) => {
      return {
        ...state.reportDataSets[index],
        ...data
      }
    })

    return dataStatistic
  },

  formatReportDataChart: state => {
    if (!state.reportRegisterChart) return

    const registerReport = deepCopy(state.reportRegisterChart)
    const dataBarChart = registerReport.dataStatistics.reduce(
      (acc, currentValue, currentIndex) => {
        acc.labels.push(currentValue.label)
        acc.datasets[0].data.push(currentValue.value)
        acc.datasets[0].backgroundColor.push(state.reportDataSets[currentIndex].borderColor)
        acc.datasets[0].borderColor.push(state.reportDataSets[currentIndex].borderColor)

        return acc
      },
      { labels: [], datasets: [{ data: [], backgroundColor: [], borderColor: [], ...COMMON_BAR_CHART_OPTIONS }] }
    )

    const datasets = registerReport.dataChart.dataSets.map((data, index) => {
      return {
        ...data,
        ...COMMON_LINE_CHART_OPTIONS,
        ...state.reportDataSets[index]
      }
    })

    const dataLineChart = {
      labels: registerReport.dataChart.labels,
      datasets
    }

    return {
      dataBarChart,
      dataLineChart
    }
  },

  formatPaymentReport: state => {
    if (
      !state.reportPaymentOrder ||
      !state.reportPaymentOrder.reportPayments ||
      !state.reportPaymentOrder.reportPayments.length
    )
      return [
        {
          value: 0,
          valueCurrency: 0,
          valueUsers: 0,
          title: `Total TOKO Users Bought`
        }
      ]

    const reportData = state.reportPaymentOrder.reportPayments
    const cryptoTypeMap = PAYMENT_CRYPTO_TYPE_DATA
    return reportData.map(item => {
      const cryptoType = cryptoTypeMap.filter(data => data.type === item.cryptoType)
      if (cryptoType.length) {
        return {
          value: item.totalCryptos,
          valueCurrency: item.totalCurrency,
          valueUsers: item.totalUsers,
          title: `Total ${cryptoType[0].name} Users Bought`
        }
      }
    })
  }
}

export default getters
