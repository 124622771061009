import { authorizationHeader } from '@/store/support/authorization'
import UserService from '@/services/user'
import * as message from '@/store/support/message'

const getDocumentCodeObject = userDocument => {
  const document = userDocument.map(document => {
    // 1: Passport, 3: card front
    if (document.documentType === 1 || document.documentType === 3) {
      return document
    }
  })

  return document[0]
}

const actions = {
  getUsers({ commit }, params) {
    return UserService.getUsers(params, authorizationHeader()).then(response => {
      commit('STORE_USERS', response.data.result)

      return response
    })
  },

  // eslint-disable-next-line no-unused-vars
  exportUsers({ commit }) {
    const params = {
      verified: 2,
      limit: 999999,
      groupBy: 'asc',
      sortBy: 'created_at'
    }

    return UserService.getUsers(params, authorizationHeader()).then(response => {
      return response
    })
  },

  async getUser({ commit, dispatch }, id) {
    const user = await UserService.getUser(id, authorizationHeader())
    const userData = user.data.result.data
    commit('STORE_USER', userData)

    if (userData.identityDocument && userData.identityDocument.length) {
      commit('STORE_USER_DOCUMENT_CODE_OBJECT', getDocumentCodeObject(userData.identityDocument))
      dispatch('getUserIdentifyImage', userData)
    }

    if (userData.referredBy > 0) {
      dispatch('getUserReferrer', userData.referredBy)
    }
  },

  async getUserIdentifyImage({ commit }, data) {
    // actually is array of promise
    const imagesURL = data.identityDocument.map(async document => {
      const image = await UserService.getUserIdentifyImage(document.documentUid, authorizationHeader())
      // create blob image
      return window.URL.createObjectURL(image.data)
    })

    const imageData = await Promise.all(imagesURL.map(image => image.catch(error => error)))
    commit('STORE_USER_IDENTIFY_IMAGES', imageData)
  },

  getUserReferrer({ commit }, id) {
    return UserService.getUser(id, authorizationHeader()).then(response => {
      commit('STORE_USER_REFERRER', response.data.result)

      return response
    })
  },

  approveUser({ commit }, id) {
    const requestData = { end_user_id: id }

    return UserService.approveUser(requestData, authorizationHeader()).then(response => {
      commit('APPROVED_USER')
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.APPROVE_USER_SUCCESS, timeOut: 2400 },
        { root: true }
      )

      return response
    })
  },

  rejectUser({ commit }, id) {
    const requestData = { end_user_id: id }

    return UserService.rejectUser(requestData, authorizationHeader()).then(response => {
      commit(
        'alert/STORE_ALERT',
        { type: 'success', content: message.REJECT_USER_SUCCESS, timeOut: 2400 },
        { root: true }
      )

      return response
    })
  },

  checkUserIdentifyDocument({ commit }, data) {
    return UserService.checkUserIdentifyDocument(data, authorizationHeader()).then(response => {
      const resultData = response.data.result.data
      commit('STORE_EXISTS_USER_DOCUMENTS', resultData)

      return response
    })
  },

  updateUserIdentifyDocument({ commit }, payload) {
    return UserService.updateUserIdentifyDocument(payload.id, { code: payload.code }, authorizationHeader()).then(
      response => {
        commit('UPDATE_USER_DOCUMENT_IDENTIFY_CODE', payload.code)
        commit(
          'alert/STORE_ALERT',
          { type: 'success', content: message.UPDATE_DOCUMENT_SUCCESS, timeOut: 2400 },
          { root: true }
        )

        return response
      }
    )
  }
}

export default actions
