import ReferralService from '@/services/referral'
import UserService from '@/services/user'
import { authorizationHeader } from '@/store/support/authorization'

const actions = {
  getReportReferrers({ commit }, params) {
    return ReferralService.getReportReferrers(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_REFERRERS', response.data.result.data)
      return response
    })
  },

  getReportReferrer({ dispatch, commit }, referrerId) {
    return ReferralService.getReportReferrer(referrerId, authorizationHeader()).then(response => {
      commit('STORE_REPORT_REFERRER', response.data.result.data)

      const userData = response.data.result.referrer
      if (userData.referredBy > 0) {
        dispatch('getUserReferrerData', userData.referredBy)
      }

      return response
    })
  },

  getUserReferrerData({ commit }, id) {
    return UserService.getUser(id, authorizationHeader()).then(response => {
      commit('STORE_REFERRER_DATA', response.data.result.data)

      return response
    })
  },

  getReportReferralRecipient({ commit }, params) {
    return ReferralService.getReportReferralRecipient(params, authorizationHeader()).then(response => {
      commit('STORE_REPORT_REFERRED_USERS', response.data.result.data)
      return response
    })
  }
}

export default actions
