export const PROGRAM_TYPE = Object.freeze({
  staking: 1,
  crowdfunding: 2,
  goldInvestment: 3
})

export const CF_REQUEST_TRANSFER_STATUS = Object.freeze({
  invalid: 0,
  requestInitial: 1,
  requestPending: 2,
  requestSuccess: 3,
  requestFailed: 4,
  rejected: 5,
  approveInitial: 6,
  approvePending: 7,
  approveSuccess: 8,
  approveFailed: 9
})

export const CF_REQUEST_TRANSFER_TNX_OPTIONS_TYPE = Object.freeze({
  invalid: 0,
  request: 1,
  approve: 2
})

export const SMART_CONTRACT_TYPE = Object.freeze({
  invalid: 0,
  stake: 1,
  erc20: 2,
  crowdfunding: 3,
  crowdfundingAdmin: 4,
  crowdfundingOracle: 5,
  usdt: 6,
  stakeV2: 9
})

export const TNX_STATUS = Object.freeze({
  invalid: 0,
  pending: 1,
  success: 2,
  failed: 3,
  error: 4,
  notFound: 5,
  symbolNotFound: 6,
  btcUnconfirmed: 7
})

export const STATUS = Object.freeze({
  invalid: 0,
  deactive: 1,
  active: 2
})

export const TNX_TYPE = Object.freeze({
  invalid: 0,
  transferERC20: 1,
  event: 2,
  staking: 3,
  referral: 4,
  doubleToko: 5,
  cfInterestRate: 6,
  cfAdmin: 7,
  cfToken: 8,
  cfOrder: 9,
  cfFeed: 10
})

export const SYS_USER_ROLE = Object.freeze({
  invalid: 0,
  admin: 1,
  member: 2
})

export const TRANSACTION_OPTION_DATA_TYPE = Object.freeze({
  all: 0,
  transferERC20: 1,
  event: 2,
  staking: 3,
  referral: 4,
  eventDoubleToko: 5,
  crowdfundingOrder: 9,
  swapToko: 11,
  goldInvestment: 0
})

export const TRANSACTION_REQUEST_TYPE = Object.freeze({
  deposit: 1,
  withdraw: 2
})

export const SWAP_TOKO_STAKING_STATUS = Object.freeze({
  invalid: 0,
  claimInit: 1,
  adminReject: 2,
  adminApprove: 3,
  adminTransferPending: 4,
  adminTransferSuccess: 5,
  adminTransferFailed: 6
})

export const SWAP_TOKO_USER_TRANSFER_STATUS = Object.freeze({
  invalid: 0,
  userTransferInit: 1,
  userTransferPending: 2,
  userTransferSuccess: 3,
  userTransferFailed: 4,
  adminReject: 5,
  adminApprove: 6,
  adminTransferPending: 7,
  adminTransferSuccess: 8,
  adminTransferFailed: 9
})

export const BLACKLIST_TYPE = Object.freeze({
  invalid: 0,
  hacker: 1
})

export const PAYMENT_ORDER_STATUS = Object.freeze({
  invalid: 0,
  initial: 1,
  pending: 2,
  failed: 3,
  imageTransferSubmited: 4,
  paid: 5,
  reject: 6,
  approve: 7,
  success: 8,
  cancel: 9,
  refund: 10,
  expire: 11
})

export const PAYMENT_TYPE = Object.freeze({
  invalid: 0,
  creditCard: 1,
  bankTransfer: 2,
  echannel: 3
})

export const PAYMENT_CRYPTO_TYPE = Object.freeze({
  invalid: 0,
  toko: 1,
  usdt: 2,
  btc: 3,
  eth: 4
})

export const PAYMENT_CRYPTO_TRANSFER_STATUS = Object.freeze({
  invalid: 0,
  pending: 1,
  failed: 2,
  success: 3
})

export const TNX_NETWORK_TYPES = Object.freeze({
  invalid: 0,
  ethereum: 1,
  bsc: 2,
  btc: 3
})

export const GOLD_INVESTMENT_SETTINGS = Object.freeze({
  tokoDepositReduce: 1,
  rateReduce: 2,
  minWithdrawGoldAmount: 3,
  withdrawFee: 4
})

export const CONVERT_TOKO_STATUS = Object.freeze({
  invalid: 0,
  sendInitial: 1,
  sendPending: 2,
  sendFailed: 3,
  sendSuccess: 4,
  sendCancel: 5,
  receivePending: 6,
  receiveFailed: 7,
  receiveSuccess: 8
})

export const CONVERT_TOKO_SETTINGS = Object.freeze({
  feeEthToBsc: 3,
  feeBscToEth: 4
})

export const REQUEST_LOG_STATUSES = Object.freeze({
  invalid: 0,
  parseJson: 3,
  waitingAdmin: 4,
  transferERC20: 5,
  dialAssetService: 6,
  dialUserService: 7,
  fail: 8,
  dialEventService: 9,
  addFeedOracle: 10,
  updateInterestRate: 11,
  dialProgramService: 12,
  convertTokoTransfer: 13
})

export const REQUEST_LOG_TYPES = Object.freeze({
  invalid: 0,
  referral: 1,
  doubleToko: 2,
  crowdfundingFeed: 3,
  crowdfundingInterestRate: 4,
  swapToko: 5,
  paymentTransfer: 6,
  convertToko: 7
})

export const STAKING_SETTINGS = Object.freeze({
  penaltyFee: 1
})
